import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TagCreate } from 'src/app/model/tag/tag-create.model';
import { Tag } from 'src/app/model/tag/tag.model';
import { AppConstants } from 'src/app/constants/app.constant';
import { TagRequestData } from 'src/app/model/tag/tag-request-data.model';
import { GridData } from 'src/app/model/ag-grid-table/grid-data.model';
import { TagResponsetData } from 'src/app/model/tag/tag-response-data.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TagManagementService {
  public behaviorSubjectForAllTagList = new BehaviorSubject<Array<any>>([]);
  behaviorSubjectForGeAllList = this.behaviorSubjectForAllTagList.asObservable();

  constructor(private httpClient: HttpClient) { }

  getTagList(params: TagRequestData): Promise<GridData<TagResponsetData>> {
    const apiUrl = AppConstants.Tag_API + 'search';
    return this.httpClient.post<GridData<TagResponsetData>>(apiUrl, params).toPromise();
  }

  createTags(tags: Array<TagCreate>): Promise<Array<Tag>> {
    const apiUrl = AppConstants.Tag_API + 'create';
    return this.httpClient.post<Array<Tag>>(apiUrl, tags).toPromise();
  }
}
