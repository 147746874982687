import { NgModule } from '@angular/core';
import { UploadDocumentComponent } from './components/upload-document/upload-document.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from "ng2-file-upload";
import { NotificationComponent } from './components/notification/notification.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HeaderComponent } from './components/header/header.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RightPanelComponent } from './components/right-panel/right-panel.component';
import { DocumentDetailComponent } from './components/document-detail/document-detail.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SharedPipeModule } from 'src/app/globalPipes/shared-pipe.module';
import { SharedDirectiveModule } from '../../globalDirectives/shared-directive.module';
import { DocumentTagsComponent } from './components/document-tags/document-tags.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { TooltipDirective } from 'src/app/globalDirectives/tooltip.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DocumentReferencePanelComponent } from './components/document-reference-panel/document-reference-panel.component';

@NgModule({
  declarations: [UploadDocumentComponent, NotificationComponent, HeaderComponent,
    RightPanelComponent,
    DocumentDetailComponent,
    DocumentTagsComponent,
    DocumentReferencePanelComponent,
  ],
  imports: [
    TranslateModule,
    CommonModule,
    FileUploadModule,
    MatSliderModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    NgMultiSelectDropDownModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    SharedPipeModule,
    SharedDirectiveModule,
    MatChipsModule,
    MatExpansionModule,
    MatIconModule,
    MatProgressBarModule,
    MatTooltipModule
  ],
  exports: [HeaderComponent,
    MatAutocompleteModule,
    MatInputModule,
    DocumentTagsComponent,
  ]
})
export class AppCommonModule { }
