<div class="header-wrapper">
    <div class="bottom-header application-header">
        <div class="d-flex align-items-center">
            <div class="menu-container  d-flex align-items-center">
                <div class="menu-icon cursor d-flex align-items-center" tooltip="About Information" placement="left">
                    <span class="material-icons"> menu </span>
                </div>
            </div>
            <div class="breadcrumb-section margin-0 d-flex align-items-center">
                <ul class="breadcrumb-items list-unstyled margin-0">
                    <li class="breadcrumb-item main d-inline">
                        {{'Manage' | translate}}
                    </li>
                    <li class="breadcrumb-item2 d-inline">
                        <span class="link-text">{{'Document Management'|translate}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
