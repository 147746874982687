import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '../../constants/app.constant';
import { BehaviorSubject } from 'rxjs';

const httpOptionsTest = {
  headers: new HttpHeaders({ 'x-ms-blob-type': 'BlockBlob' }),
};
@Injectable({
  providedIn: 'root',
})
export class UploadDocumentService {
  constructor(private http: HttpClient) { }

  public behaviorSubjectForDocumentCreate = new BehaviorSubject([]);
  getObserverForDocumentCreate = this.behaviorSubjectForDocumentCreate.asObservable();

  getListItemsByListType(ListType: any): Promise<any> {
    const apiUrl = AppConstants.Ehub_API + 'listManagement/getListItemsByListType?listType=' + ListType;
    return this.http.get(apiUrl).toPromise();
  }

  getCurrentLoggedUserDetails(): Promise<any> {
    const apiUrl = AppConstants.Ehub_API + 'usersNew/getUserProfile';
    return this.http.get(apiUrl, { params: { listType: 'Alert Status' } }).toPromise();
  }

  getTagList() {
    let apiUrl = '../../../assets/json/tagListTemp.json';
    return this.http.get(apiUrl);
  }

  getDocumentLocation(file_names): Promise<any> {
     const apiUrl = AppConstants.Document_API + 'getDocumentLocation';
    return this.http.post(apiUrl, {file_names, location: "DRAFT"} ).toPromise();
  }

  callPresignedUrl(params, url): Promise<any> {
    if(AppConstants.Cloud == 'AZURE') {
      return this.http.put(url, params, httpOptionsTest).toPromise();
    } else {
      return this.http.put(url, params).toPromise();
    }
  }

  validationDocument(validateDocumentList): Promise<any> {
    const apiUrl = AppConstants.Document_API + 'validateDocuments';
    return this.http.post(apiUrl, validateDocumentList).toPromise();
  }

  getValidateResult(parems): Promise<any> {
    const apiUrl = AppConstants.Document_API + 'getJobResult';
    return this.http.get(apiUrl, { params: parems }).toPromise();
  }

  uploadDocument(params): Promise<any> {
    const apiUrl = AppConstants.Document_API + 'uploadDocuments';
    return this.http.post(apiUrl, params).toPromise();
  }

  uploadDocumentResultMessage(parems): Promise<any> {
    const apiUrl = AppConstants.Document_API + 'getJobResult'
    return this.http.get(apiUrl, { params: parems }).toPromise();
  }

  getDocumentStatusList() {
    let apiUrl = '../../../assets/json/documentStatusTemp.json';
    return this.http.get(apiUrl).toPromise();
  }

  addDocumentCategory(params): Promise<any> {
    const apiUrl = AppConstants.Ehub_API + 'listManagement/saveOrUpdateListItem';
    return this.http.post(apiUrl, params).toPromise();
  }

  createTagMapping(params): Promise<any> {
    const apiUrl = AppConstants.Tag_API + 'tagmappings/create';
    return this.http.post(apiUrl, params).toPromise();
  }
}
