import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'src/app/constants/app.constant';


@Injectable({ providedIn: 'root' })
export class LocalizationService {

    private traslations: Promise<any>;

    constructor(private translateService: TranslateService) { }

    setLocalization(fileName: string, language: string): void {
        const apiUrl = AppConstants.Ehub_API +
            `fileStorage/downloadFileByLanguageAndFileName?fileName=${this.getFileName(fileName, language)}&languageName=${language}`;
        this.translateService.setDefaultLang(apiUrl);
        this.getAllTranslations().then(() => { () => ({}) });
    }

    getTranslation(key: string): string {
        return this.translateService.instant(key);
    }

    getAllTranslations(): Promise<any> {
        return this.translateService.get(['']).toPromise();
    }

    private getFileName(file: string, language: string): string {
        if (language.toLowerCase() === 'german') {
            return 'de_' + file;
        }
        return 'en_' + file;
    }

}
