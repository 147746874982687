import { TagManagementService } from './../../../../services/api/tag-management.service';
import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
  VERSION,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploader } from 'ng2-file-upload';
import { UploadDocumentService } from 'src/app/services/api/upload-document.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { Form, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { SharedService } from 'src/app/services/shared/shared.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/api/common.service';
import { Category } from 'src/app/model/shared/drop-down-data.model';
import { ToastrService } from 'ngx-toastr';
import { GlobalConstants, NOTIFICATION_TIMEOUT_3000 } from 'src/app/constants/global.constant';
import * as moment from 'moment';
import { LocalizationService } from 'src/app/services/shared/localization.service';
import { TagRequestData } from 'src/app/model/tag/tag-request-data.model';
@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
})
export class UploadDocumentComponent implements OnInit {
  private static VALIDATE_RESPONSE_TIMEOUT = 20000;
  private static VALIDATE_RESPONSE_COUNT = 20;
  interval: any;
  screeningStarted: boolean;
  describeScreen: boolean;
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  fileList: any = [];
  uploadList: any = [];
  uploadListFormArray = new FormArray([]);
  tagMappingFormArray = new FormArray([]);
  versionList: any = [];
  copyFiles: any = [];
  formatNotAllowed: any = [];
  enableRunButton: boolean;
  fileSizeFromSystemSettings: any;
  SSB_files_allowed: any = [];
  validationsResult: any = [];
  versionScreen: boolean;
  versionNotification;
  newvesionAll: boolean;
  OverrideAll: boolean;
  newdocumentAll: boolean;
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  ListType: string;
  tagList: any = [];
  confidencePercentage = 40;
  CategoryList = [];
  private metadata = [];
  spinner: boolean;
  submitSpinner: boolean;
  private uploadFileCount: number;
  private user: any;
  uploadScreen: boolean = true;
  radioButton: boolean;
  filteredCategorys: Observable<Category[]>;
  systemSettingData: any;
  private uploadFileList = [];
  private validateDocumentList = [];
  settingsSpinner: boolean;
  @ViewChild('CategorySingleSelect') singleSelect: MatSelect;
  categoryControl = new FormControl('');
  permissionIdsList: any[];
  secondStep: boolean;
  versionStep: boolean;
  firstStep: boolean;
  isCategoryCreateAvilable: boolean;
  fileUploadGroup: FormGroup;
  tagMappingGroup: FormGroup;
  categoryAuto;
  categoryAutoId;
  private NOTIFICATION_TIMEOUT = 30000;
  exisitingDocumentLoader: boolean;
  filePaths = [];
  submitFilePath;
  titleSubmitError: boolean;
  titleErrorCount = [];
  documentMetadataPermission: any;
  matProgress = 0;
  lastMatProgress = 0;

  constructor(
    public dialogReference: MatDialogRef<UploadDocumentComponent>,
    private uploadDocumentService: UploadDocumentService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private tocstr: ToastrService,
    private localizationService: LocalizationService,
    private tagManagementService:TagManagementService,
    @Inject(MAT_DIALOG_DATA) public incomingdata: any
  ) { }

  ngOnInit(): void {
    this.getPermissionIds();
    this.onAfterAddingFile();
    this.getSystemSettings();
    this.getDocumentCategory();
    this.getLoggedUser();
    this.getTagList();

    this.fileUploadGroup = new FormGroup({
      'uploadListFormArray': this.uploadListFormArray
    })

    this.tagMappingGroup = new FormGroup({
      'tagMappingFormArray': this.tagMappingFormArray
    })

    this.getValue();
  }

  displayFn(Category): string {
    return Category && Category.displayName ? Category.displayName : '';
  }

  getValue() {
    var UploadFormArray = this.fileUploadGroup.get('uploadListFormArray') as FormArray;
    var UploadFormArrayValue = UploadFormArray.at(this.categoryAutoId);

    if (UploadFormArrayValue) {
      this.filteredCategorys = UploadFormArrayValue['controls'].category.valueChanges.pipe(
        startWith(''),
        map((e) => (typeof e === 'string' ? e : '')),
        map((name) => (this.categoryAuto ? this._filter(this.categoryAuto) : name ? this._filter(name) : this.CategoryList.slice()))
      );
    } else {
      this.filteredCategorys = this.fileUploadGroup.controls.uploadListFormArray.valueChanges.pipe(
        startWith(''),
        map((e) => (typeof e === 'string' ? e : '')),
        map((name) => (name ? this._filter(name) : this.CategoryList.slice()))
      );
    }
  }

  private _filter(name) {
    const filterValue = name.toLowerCase();
    if (filterValue) {
      this.isCategoryCreateAvilable = true;

      this.CategoryList.forEach(category => {
        if (category.displayName.toLowerCase() === filterValue) {
          this.isCategoryCreateAvilable = false;
        }
      })
    }
    return this.CategoryList.filter((option) =>
      option.displayName.toLowerCase().includes(filterValue)
    );
  }

  onAfterAddingFile() {
    this.uploader = new FileUploader({
      url: '',
      disableMultipart: true,
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date(),
          });
        });
      },
    });
    this.hasBaseDropZoneOver = false;
    this.uploader.onAfterAddingFile = (item) => {
      item.remove();
      this.spinner = false;
      if (
        this.uploader.queue.filter((f) => f._file.name == item._file.name)
          .length == 0
      ) {
        this.uploader.queue.push(item);
      }
    };
  }

  radioChange(event) {
    if (event.value == 'newvesionAll') {
      this.radioButton = true;
      for (let i = 0; i < this.versionList.length; i++) {
        this.versionList[i].version_handler = 'new version';
      }
    } else {
      this.newvesionAll = false;
    }
    if (event.value == 'OverrideAll') {
      this.radioButton = true;
      for (let i = 0; i < this.versionList.length; i++) {
        this.versionList[i].version_handler = 'override';
      }
    } else {
      this.OverrideAll = false;
    }
    if (event.value == 'newdocumentAll') {
      this.radioButton = true;
      for (let i = 0; i < this.versionList.length; i++) {
        this.versionList[i].version_handler = 'new file';
      }
    } else {
      this.newdocumentAll = false;
    }
    if (event.value) {
      this.radioButton = true;
    } else {
      this.radioButton = false;
    }
  }

  close(): void {
    if (this.screeningStarted) {
      let modalEle: any = document.getElementsByClassName(
        'cdk-overlay-container'
      )[0];
      modalEle.style.display = 'none';
      modalEle.style.height = 'inherit';
    } else {
      this.dialogReference.close();
    }
  }

  cancelUpload(): void {
    this.dialogReference.close();
    clearInterval(this.interval);
  }

  onFileSelected(event: EventEmitter<File[]>): void {
    for (let i = 0; i < (event && event.length); i++) {
      const file: File = event[i];
      let index = this.fileList.findIndex((files) => {
        return file.name == files.name;
      });
      if (index === -1) {
        this.fileList.push(file);
        this.enableRunButton = true;
      } else {
        const message: string = this.localizationService.getTranslation('File with is already selected to be uploaded. File names need to be unique');
        message.replace('{1}', file.name);
        this.versionNotification = false;
        this.notificationService.error(
          `<span><i class="fas fa-exclamation-circle file-name-icon"></i></i><span class="alert-text file-name-alert">${message}</span></span>`,
          this.options
        );
      }
    }
  }

  removeUploadedDoc(item): void {
    this.spinner = false;
    let fileLength =
      this.uploader && this.uploader.queue && this.uploader.queue.length;
    if (fileLength > 0) {
      for (let i = 0; i < this.uploader.queue.length; i++) {
        if (this.uploader.queue[i]._file.name === item._file.name) {
          this.uploader.queue[i].remove();
          this.fileList.splice(i, 1);
          if (fileLength == 1) {
            this.enableRunButton = false;
          }
          return;
        }
      }
    }
  }

  checkSystemValuses(value) {
    if (
      value.name == 'Maximum file size allowed' &&
      value.section == 'File Settings'
    ) {
      this.fileSizeFromSystemSettings = value.selectedValue;
      if (this.fileSizeFromSystemSettings.indexOf('MB')) {
        this.fileSizeFromSystemSettings = Number(
          this.fileSizeFromSystemSettings.split('MB')[0]
        );
      }
    }
    if (
      value.type === 'Toggle On/Off' &&
      value.section === 'File Settings' &&
      value.systemSettingType === 'GENERAL_SETTING' &&
      value.selectedValue === 'On'
    ) {
      this.SSB_files_allowed.push(value.name);
    }
  }

  getSystemSettings(): void {
    this.settingsSpinner = true;
    this.systemSettingData = this.sharedService.getSystemSettings();
    if (this.systemSettingData && this.systemSettingData['General Settings']) {
      this.systemSettingData['General Settings'].forEach((val) => {
        this.checkSystemValuses(val);
        this.settingsSpinner = false;
      });
    }

    if (
      !this.systemSettingData ||
      !this.systemSettingData['General Settings']
    ) {
      this.commonService.getGeneralSettings().then((res) => {
        this.systemSettingData = res;
        if (
          this.systemSettingData &&
          this.systemSettingData['General Settings']
        ) {
          this.systemSettingData['General Settings'].forEach((val) => {
            this.checkSystemValuses(val);
            this.settingsSpinner = false;
          });
        }
      });
    }
  }

  getTagList() {
    const params: TagRequestData = { entity_type: 'Document', only_tags: true, "tag_entities": true, filter_model: '{}' };
    this.tagManagementService.getTagList(params).then((res) => {
      if(res && res.result){
        this.tagManagementService.behaviorSubjectForAllTagList.next(res.result)
      }
    });
  }

  getDocumentCategory(): void {
    this.ListType = 'Document Category';
    this.uploadDocumentService
      .getListItemsByListType(this.ListType)
      .then((res) => {
        this.CategoryList = res;
      });
  }

  getValidateResult(parems, count: number = 0): void {
    let value = parems;
    this.spinner = true;
    this.copyFiles = [];
    this.formatNotAllowed = [];
    if (count < UploadDocumentComponent.VALIDATE_RESPONSE_COUNT) {
      this.uploadDocumentService.getValidateResult(parems).then((res) => {
        if (res.status == 'finished') {
          this.matProgress = 100;
          var uploadResult = res.result;
          uploadResult.forEach((element) => {
            if (element && element.result && typeof (element.result) == 'object' && element.result.is_error) {
              this.sharedService.getErrorMessage('<span class="upload-fail">Something went wrong!</span>', NOTIFICATION_TIMEOUT_3000)
            } else {
              element.result.forEach((file) => {
                this.uploadList.push(file);
              });
              let fileFormat = element.file_name && element.file_name.split('.').length > 0 ? element.file_name.split('.').pop() : '';
              if (fileFormat == 'zip') {
                element.result.forEach(file => {
                  let filePath = {
                    'fileName': file.file_name,
                    'filePath': file.file_path
                  }
                  this.filePaths.push(filePath)
                });
              }
            }
          });

          for (let i = 0; i < this.uploadList.length; i++) {
            if (this.uploadList[i].result == false) {
              if (this.uploadList[i].reason.includes('File exists try new version')) {
                this.versionList.push(this.uploadList[i]);
              } else if (this.uploadList[i].reason.includes('Document is already exists')) {
                this.copyFiles.push(this.uploadList[i]);
              } else if (this.uploadList[i].reason.includes('Format not allowed')) {
                this.formatNotAllowed.push(this.uploadList[i])
              }
            }
          }

          if (this.uploadList.length <= this.copyFiles.length) {
            this.copyFiles.forEach(element => {
              const message: string = this.localizationService.getTranslation('File already exists in the repository');
              this.notificationService.error(
                `<span><i class="fas fa-exclamation-circle m-b-60"></i></i><span class="alert-text">
                  ${element.file_name}
                  </br> ${message} </span></span>`,
                this.options
              );
              var item = { '_file': { 'name': element && element.file_name } }
              this.removeUploadedDoc(item);
            });
            this.spinner = false;
          } else {
            if (this.formatNotAllowed.length) {
              this.formatNotAllowed.forEach(copy => {
                uploadResult.forEach((upload, i) => {
                  if (copy.file_name == upload.file_name) {
                    uploadResult.splice(i, 1)
                  }
                });
                this.uploadList.forEach((elementUpload, i) => {
                  if (copy.file_name == elementUpload.file_name) {
                    this.uploadList.splice(i, 1)
                  }
                });
              });
            }
            this.uploadFileCount = this.uploadList.length - this.copyFiles.length
            if (this.copyFiles.length) {
              this.copyFiles.forEach(copy => {
                uploadResult.forEach((upload, i) => {
                  if (copy.file_name == upload.file_name) {
                    uploadResult.splice(i, 1)
                  }
                });
                this.uploadList.forEach((elementUpload, i) => {
                  if (copy.file_name == elementUpload.file_name) {
                    this.uploadList.splice(i, 1)
                  }
                });
              });
            }

            if (this.versionList.length) {
              this.describeScreen = false;
              this.uploadScreen = false;
              this.versionScreen = true;
              this.spinner = false;
            } else {
              this.uploadList.forEach((file) => {
                (<FormArray>this.fileUploadGroup.get('uploadListFormArray')).push(
                  new FormGroup({
                    'title': new FormControl(file.title, Validators.required),
                    'category': new FormControl(''),
                    'file_name': new FormControl(file.file_name),
                    'result': new FormControl(file.result),
                    'version_handler': new FormControl(file.version_handler),
                    'analysis': new FormControl(''),
                    'message': new FormControl(file.message),
                    'size': new FormControl(file.size),
                    'format': new FormControl(file.format),
                    'last_updated': new FormControl(file.last_updated),
                    'reference_count': new FormControl(file.reference_count)
                  })
                )
              });
              this.describeScreen = true;
              this.uploadScreen = false;
              this.spinner = false;
              this.versionScreen = false;
              this.confidencePercentage = 100;
            }
          }

        } else if (res.status == 'running') {
          this.matProgress = 80;
          this.getValidateResult(value, count + 1);
          if (count == 19) {
            var message = '<div class="upload-fail">Uploading Failed... Please try again</div>'
            this.uploadFileList = [];
            this.validateDocumentList = [];
            this.metadata = [];
            this.versionList = [];
            this.filePaths = [];
            this.sharedService.getErrorMessage(message, NOTIFICATION_TIMEOUT_3000)
            this.spinner = false;
          }
        }
      }, (err) => {
        this.sharedService.getErrorMessage('<span class="upload-fail">Something went wrong!</span>', NOTIFICATION_TIMEOUT_3000)
      });
    }
  }

  public get uploadListFormTest() {
    return this.fileUploadGroup.get('uploadListFormArray') as FormArray
  }

  handleInputChange(uploadListForm, value, event) {
    this.titleErrorCount = [];
    let fileName = value.file_name && value.file_name.split('.').slice(0, -1)[0];
    if (fileName == event && value.version_handler == 'new file') {
      value['titleError'] = true;
    } else {
      value['titleError'] = false;
    }

    if (this.uploadListFormArray.value.length) {
      this.uploadListFormArray.value.forEach((uploadFile) => {
        if (uploadFile.titleError) {
          this.titleErrorCount.push('TError')
        }
      })
    }

    if (this.titleErrorCount.length > 0) {
      this.titleSubmitError = true;
    } else {
      this.titleSubmitError = false;
    }
  }

  getValidationMessage(validationsPassed): void {
    this.matProgress = 10;
    this.spinner = true;
    this.uploadList = [];
    this.versionList = [];
    this.filePaths = [];
    var PresignedUrlRes = [];
    this.validationsResult.forEach(element => {
      if (element && element.file && element.states && element.states == 'fail') {
        var item = { '_file': { 'name': element && element.file } }
        this.removeUploadedDoc(item);
        this.describeScreen = false;
        this.validationsResult = [];
        this.metadata.forEach((file, i) => {
          if (file && file.fileName && file.fileName == element.file) {
            this.metadata.splice(i, 1)
          }
        })
        this.uploadFileList.forEach((file, i) => {
          if (file == element.file) {
            this.uploadFileList.splice(i, 1)
          }
        })
        this.validateDocumentList.forEach((file, i) => {
          if (file && file.fileName == element.file) {
            this.validateDocumentList.splice(i, 1)
          }
        })
      }
    })

    if (this.metadata.length && this.uploadFileList.length) {
      this.uploadDocumentService
        .getDocumentLocation(this.uploadFileList)
        .then((res) => {
          this.matProgress = 20;
          this.filePaths = res;
          if (res) {
            for (let i = 0; i < res.length; i++) {
              const found = this.metadata.find(
                (element) => element.fileName === res[i].fileName
              );

              if (this.filePaths.length) {
                this.validateDocumentList.forEach(validateElement => {
                  this.filePaths.forEach(path => {
                    if (validateElement.fileName == path.fileName) {
                      validateElement['filePath'] = path.filePath;
                    }
                  })
                });
              }

              if (found && found.fileContent) {
                this.uploadDocumentService
                  .callPresignedUrl(found.fileContent, res[i].presignedUrl)
                  .then((response) => {
                    PresignedUrlRes.push('response');
                    if (PresignedUrlRes.length == res.length && this.validateDocumentList) {
                      this.uploadDocumentService.validationDocument(this.validateDocumentList).then((res) => {
                        if (res) {
                          this.matProgress = 50;
                          var parems = { jobId: res['jobId'] };
                          this.getValidateResult(parems);
                        }
                      },
                        () => {
                          this.spinner = false;
                        });
                    }
                  });
              }
            }
          }
        });
    }
    this.validationsResult = [];

    return validationsPassed;
  }

  getMetadatafunction(file, parems, index): void {
    let validationsPassed = false;
    let fileFormat =
      parems[index] &&
        parems[index].name &&
        parems[index].name.split('.').length > 0
        ? parems[index].name.split('.').pop()
        : '';
    let fileSize =
      parems[index] && parems[index].size
        ? parems[index].size / 1024 / 1024
        : 0;
    let fileName =
      parems[index] && parems[index].name ? parems[index].name : '';

    let metadataForValidation = {
      fileName: fileName,
      fileContent: file,
    };

    let validateDocumentmetadata = {
      fileName: fileName,
      format: fileFormat,
      size: fileSize.toString(),
      versionHandler: '',
      title: '',
      lastUpdated: moment(file.lastModifiedDate).format("YYYY-MM-DD hh:mm:ss"),
      createdBy:
        (this.user && this.user.userId) ? this.user.userId.toString() : '',
    };
    this.metadata.push(metadataForValidation);
    this.uploadFileList.push(fileName);
    this.validateDocumentList.push(validateDocumentmetadata);

    let validationFileFormatStatus = this.validateUploadedFileFormat(
      fileFormat,
      fileName
    );
    var valideFile = {};
    if (!validationFileFormatStatus) {
      validationsPassed = false;
      valideFile = {
        'states': 'fail',
        'file': fileName
      }
      this.validationsResult.push(valideFile);

    } else {
      validationsPassed = true;
      valideFile = {
        'states': 'pass',
        'file': fileName
      }
      this.validationsResult.push(valideFile);
    }

    let validationFileSizeStatus = this.validateUploadedFileSize(
      fileSize,
      fileName
    );
    if (!validationFileSizeStatus) {
      validationsPassed = false;
      valideFile = {
        'states': 'fail',
        'file': fileName
      }
      this.validationsResult.push(valideFile);
    } else {
      valideFile = {
        'states': 'pass',
        'file': fileName
      }
      this.validationsResult.push(valideFile);
    }

    if (index === parems.length - 1) {
      this.getValidationMessage(validationsPassed);
    }
  }

  validateUploadedFiles(): void {
    this.exisitingDocumentLoader = false
    this.metadata = [];
    this.uploadFileList = [];
    this.validateDocumentList = [];
    this.versionList = [];
    this.filePaths = [];
    let files = this.fileList;

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      this.getMetadatafunction(file, files, index);
    }
  }

  getLoggedUser(): void {
    this.uploadDocumentService.getCurrentLoggedUserDetails()
      .then((resp) => {
        this.user = resp;
      });
  }

  coutinueVersionUpload(): void {
    this.titleErrorCount = [];
    this.exisitingDocumentLoader = true;
    this.describeScreen = true;
    this.uploadScreen = false;
    this.spinner = false;
    this.versionScreen = false;
    this.confidencePercentage = 100;
    if (this.uploadList && this.uploadList.length) {
      this.uploadList.forEach((uploadFile) => {
        if (uploadFile) {
          if (
            !uploadFile.result &&
            uploadFile.version_handler != 'new file' &&
            uploadFile.version_handler != undefined
          ) {
            this.versionNotification = true;
            if (uploadFile.version_handler == 'new version') {
              uploadFile['message'] = 'File will be uploaded as a new version.';
            }

            if (uploadFile.version_handler == 'override') {
              uploadFile['message'] = 'File will override current version.';
            }
          } else if (uploadFile.version_handler == 'new file') {
            uploadFile['titleError'] = true;
          }
        }

        (<FormArray>this.fileUploadGroup.get('uploadListFormArray')).push(
          new FormGroup({
            'title': new FormControl(uploadFile.title),
            'category': new FormControl(''),
            'file_name': new FormControl(uploadFile.file_name),
            'result': new FormControl(uploadFile.result),
            'version_handler': new FormControl(uploadFile.version_handler),
            'analysis': new FormControl(''),
            'message': new FormControl(uploadFile.message),
            'size': new FormControl(uploadFile.size),
            'format': new FormControl(uploadFile.format),
            'last_updated': new FormControl(uploadFile.last_updated),
            'titleError': new FormControl(uploadFile.titleError),
            'reference_count': new FormControl(uploadFile.reference_count)
          })
        )
      })
    }

    if (this.uploadListFormArray.value.length) {
      this.uploadListFormArray.value.forEach((uploadFile) => {
        if (uploadFile.titleError) {
          this.titleErrorCount.push('TError')
        }
      })
    }

    if (this.titleErrorCount.length > 0) {
      this.titleSubmitError = true;
    } else {
      this.titleSubmitError = false;
    }
  }

  submitUploadResult(): void {
    this.lastMatProgress = 20;
    this.metadata = [];
    this.submitSpinner = true;
    if (this.uploadListFormArray && this.uploadListFormArray.value) {
      this.uploadListFormArray.value.forEach((uploadFile) => {
        if (uploadFile) {
          let metadataforUpload = {
            fileName: uploadFile.file_name ? uploadFile.file_name : '',
            size: uploadFile.size ? uploadFile.size.toString() : '0',
            format: uploadFile.format ? uploadFile.format : '',
            title: uploadFile.title ? uploadFile.title : '',
            updatedBy:
              (this.user && this.user.userId) ? this.user.userId.toString() : '',
            status: 'New',
            category: (uploadFile.category && uploadFile.category['code'])
              ? uploadFile.category['code'].toString()
              : '',
            versionHandler: uploadFile.version_handler
              ? uploadFile.version_handler
              : 'new file',
            analysis: true,
            lastUpdated: uploadFile.last_updated ? uploadFile.last_updated : '',
            filePath: this.getFilePath(uploadFile && uploadFile.file_name),
            timestamp: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
            createdBy:
              (this.user && this.user.userId) ? this.user.userId.toString() : '',
            reference_count: uploadFile.version_handler == 'override' ? uploadFile.reference_count : 0
          };
          this.metadata.push(metadataforUpload);
        }
      });
    }

    var metadata = this.metadata;
    this.uploadDocumentService.uploadDocument(metadata).then((res) => {
      if (res) {
        this.lastMatProgress = 40;
        var params = {
          jobId: res['jobId'],
        };
        this.uploadDocumentResultMessage(params);
      }
    });
  }

  getFilePath(file_name) {
    if (file_name) {
      this.filePaths.forEach(element => {
        if (element.fileName == file_name) {
          this.submitFilePath = element.filePath;
        }
      })
    }
    return this.submitFilePath;
  }

  uploadDocumentResultMessage(params, count: number = 0): void {
    let value = params;
    if (count < UploadDocumentComponent.VALIDATE_RESPONSE_COUNT) {
      this.uploadDocumentService
        .uploadDocumentResultMessage(params)
        .then((res) => {
          if (res.status == 'finished') {
            this.lastMatProgress = 100;
            this.submitSpinner = false;
            this.uploadDocumentService.behaviorSubjectForDocumentCreate.next(
              res.result
            );
            if (res.result) {
              var tagArray = [];
              this.tagMappingGroup.value.tagMappingFormArray.forEach(tagElement => {
                res.result.forEach(element => {
                  if (tagElement && tagElement.item && tagElement.item.file_name == element.file_name) {
                    element.result.forEach(file => {
                      if (file && file.file_id) {
                        tagElement.tags.forEach(tagElement => {
                          if (tagElement) {
                            var tag =
                            {
                              "tag_id": tagElement.tag && tagElement.tag.id,
                              "subtag_id": tagElement.sub_tags && tagElement.sub_tags[0] && tagElement.sub_tags[0].id,
                              "entity1_id": file.file_id,
                              "entity1_type": "Document",
                            }
                            tagArray.push(tag);
                          }
                        });
                      }
                    });
                  }
                });
              });
              if (tagArray.length) {
                this.uploadDocumentService.createTagMapping(tagArray).then(res => {() => res})
              }
            }
            this.dialogReference.close();
          } else if (res.status == 'running') {
            this.lastMatProgress = 70;
            this.uploadDocumentResultMessage(value, count + 1);
            this.submitSpinner = true;
            if (count == 19) {
              var message = '<div class="upload-fail">Uploading Failed... Please try again</div>'
              this.submitSpinner = false;
              this.sharedService.getErrorMessage(message, NOTIFICATION_TIMEOUT_3000)
            }
          }
        }, (err) => {
          this.sharedService.getErrorMessage('<span class="upload-fail">Something went wrong!</span>', NOTIFICATION_TIMEOUT_3000)
        });
    }
  }

  validateUploadedFileFormat(fileFormat, fileName): boolean {
    // if (fileFormat == 'zip') {
    //   fileFormat = 'Zip Archived';
    // }
    if (fileFormat && this.SSB_files_allowed.includes(fileFormat)) {
      return true;
    } else {
      this.versionNotification = false;
      const message: string = this.localizationService.getTranslation('File format is not supported. Please choose files that are');
      this.notificationService.error(
        `<span><i class="fas fa-exclamation-circle m-b-60"></i></i><span class="alert-text">
        ${fileName}
        </br>${message} [
      ${this.SSB_files_allowed.join(', ')}
        ]</span></span>`,
        this.options
      );
    }
    return false;
  }

  validateUploadedFileSize(fileSize, fileName): boolean {
    if (
      this.fileSizeFromSystemSettings &&
      (fileSize <= this.fileSizeFromSystemSettings && fileSize > 0 )
    ) {
      return true;

    }
    if(fileSize == 0){
      this.enableRunButton = false;
      this.versionNotification = false;
      const message: string = this.localizationService.getTranslation('File could not be uploaded.file size must be more than 0MB');
      this.notificationService.error(
        `<span><i class="fas fa-exclamation-circle m-b-60"></i></i><span class="alert-text">
        ${fileName}
        </br>${message}
         </span></span>`,
        this.options
      );
      return false;
    }
    else {
      this.enableRunButton = false;
      this.versionNotification = false;
      const message: string = this.localizationService.getTranslation('File could not be uploaded. Maximum upload file size');
      this.notificationService.error(
        `<span><i class="fas fa-exclamation-circle m-b-60"></i></i><span class="alert-text">
        ${fileName}
        </br>${message}
        ${this.fileSizeFromSystemSettings}
        MB. </span></span>`,
        this.options
      );
    }
    return false;
  }

  backtoupload(): void {
    this.describeScreen = false;
    this.versionScreen = false;
    this.uploadScreen = true;
    this.newvesionAll = false;
    this.OverrideAll = false;
    this.newdocumentAll = false;
    this.uploadFileList = [];
    this.validateDocumentList = [];
    this.metadata = [];
    this.filePaths = [];
    this.uploadListFormTest.controls.length = 0
  }

  deleteUplodedFile(filename): void {
    if (this.versionScreen) {
      this.versionList.forEach((element, index) => {
        if (element.file_name === filename) {
          this.versionList.splice(index, 1);
          if (this.versionList.length < 1) {
            this.versionScreen = false;
            this.uploadScreen = true;
            this.spinner = false;
          }
        }
      });
    }

    if (this.uploadList.length) {
      this.uploadList.forEach((element, index) => {
        if (element.file_name === filename) {
          this.uploadList.splice(index, 1);
          this.uploadFileCount = this.uploadList && this.uploadList.length;
          if (this.uploadList.length < 1) {
            this.describeScreen = false;
            this.uploadScreen = true;
          }
        }
      });
    }
    let fileLength =
      this.uploader && this.uploader.queue && this.uploader.queue.length;
    if (fileLength > 0) {
      this.uploader.queue.forEach((element, index) => {
        if (element._file.name === filename) {
          this.uploader.queue[index].remove();
          this.fileList.splice(index, 1);
          if (fileLength == 1) {
            this.enableRunButton = false;
          }
          return;
        }
      });
    }
    if (this.uploadListFormTest.controls.length) {
      this.uploadListFormTest.controls.forEach((element, index) => {
        if ((element && element.value && element.value.file_name) && (element.value.file_name === filename)) {
          this.uploadListFormTest.controls.splice(index, 1);
        }
      });
    }
  }

  getPermissionIds() {
    const permissions: any[] = this.sharedService.getPermissions();
    if (permissions && permissions['documentManagement']) {
      this.sharedService.behaviorSubjectForAllPermisonIds.next(
        permissions['documentManagement']
      );
      this.sharedService.behaviorSubjectForAllPermisonIds$.subscribe((ids) => {
        this.permissionIdsList = ids;
        this.documentMetadataPermission = this.getPermissionForColumn('documentMetadata');
      });
    }
  }

  async createNewCategory(category, item) {
    var params = {
      "allowDelete": true,
      "code": category,
      "colorCode": "",
      "disableInput": true,
      "displayName": category,
      "flagName": "",
      "flagPath": "",
      "icon": "ban",
      "listItemId": "",
      "listType": "Document Category"
    }
    await this.uploadDocumentService.addDocumentCategory(params).then(res => {
      if (res) {
        this.CategoryList.push(res);
        GlobalConstants.categoryList.push(res)
        item.controls['category'].setValue(res);
        this.isCategoryCreateAvilable = false;
      }
    })
  }

  getModelChange(event, i) {
    this.categoryAuto = '';
    this.categoryAutoId = i;
    this.isCategoryCreateAvilable = false;
    if (event == "" || event == undefined) {
      this.isCategoryCreateAvilable = false;
    } else if (event.constructor === String) {
      this.categoryAuto = event;
      this.getValue()
    } else {
      this.isCategoryCreateAvilable = false;
    }
    let result = this.CategoryList.filter(singleCategory =>
      (event && singleCategory.displayName) ?
        singleCategory.displayName === event : null
    )
    if (result && !result.length) {
      this.isCategoryCreateAvilable = true
    }
  }

  getSelectedtags(event, item): void {
    if (this.tagMappingFormArray && this.tagMappingFormArray.value && this.tagMappingFormArray.value.length > 0) {
      for (let i = 0; i < this.tagMappingFormArray.value.length; i++) {
        if (this.tagMappingFormArray.value[i].item && this.tagMappingFormArray.value[i].item.file_name != item.value.file_name) {
          if (this.tagMappingFormArray.value[i + 1] == null) {
            (<FormArray>this.tagMappingGroup.get('tagMappingFormArray')).push(
              new FormGroup({
                'tags': new FormControl(event),
                'item': new FormControl(item.value)
              })
            )
          }
        } else {
          this.tagMappingFormArray.removeAt(i);
          (<FormArray>this.tagMappingGroup.get('tagMappingFormArray')).push(
            new FormGroup({
              'tags': new FormControl(event),
              'item': new FormControl(item.value)
            })
          )
        }
      }
    } else {
      (<FormArray>this.tagMappingGroup.get('tagMappingFormArray')).push(
        new FormGroup({
          'tags': new FormControl(event),
          'item': new FormControl(item.value)
        })
      )
    }
  }

  getPermissionForColumn(key) {
    const permission = this.sharedService.getDomainPermissions(this.permissionIdsList, key);
    const permissionLevel = this.sharedService.getPermissionStatusType(permission);
    return permissionLevel;

  }
}
