import { Component, AfterViewInit, ElementRef, ViewChild, Input, Output, EventEmitter, ViewChildren, QueryList, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatAccordion } from '@angular/material/expansion';
import { Observable } from 'rxjs';
import { map, startWith, filter } from 'rxjs/operators';
import { TagManagementService } from 'src/app/services/api/tag-management.service';
import { TagCreate } from 'src/app/model/tag/tag-create.model';
import { Tag } from 'src/app/model/tag/tag.model';

@Component({
  selector: 'app-document-tags',
  templateUrl: './document-tags.component.html',
  styleUrls: ['./document-tags.component.scss']
})
export class DocumentTagsComponent implements AfterViewInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagFormControl = new FormControl();
  filteredTags: Observable<any[]>;
  newTags = [];
  newSubTags = [];
  filteredTagList;
  isTagFound: boolean;
  isError: boolean;
  errorMessage: string;
  alltags: any[];
  inputFiled: string;
  removedSubTag = [];
  defaultColorCode = "#42b7ff";
  chipsTotalWidth: number;
  hiddenTag = [];
  isEditMode: boolean;
  isShowInput:boolean = true;
  vissibleTagList = []
  tagsToShow = [];

  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoComplete', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatAutocompleteTrigger, { static: false }) autoCompleteDropdown: MatAutocompleteTrigger;
  @Input('selectedTag') selectedTag;
  @Input('isOverlapping') isOverlapping: boolean;
  @Input('isEnableCreateNew') isEnableCreateNew: boolean;
  @Output('onSeletionTag') onSeletionTag: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output('onRemoveTag') onRemoveTag: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild('tagWrapper', { static: true }) tagWrapper;
  @ViewChildren('tagElem', { read: ElementRef }) tagElem: QueryList<ElementRef>;
  @ViewChild('inputElement') inputElement: ElementRef;
  @ViewChild('contentElement') contentElement: ElementRef;

  constructor(private tagMangementService: TagManagementService,
    private renderer: Renderer2) {
      this.listenInputFocus()
    }

  ngOnInit() {
    if (this.selectedTag) {
      this.newTags = this.selectedTag
    }
    this.tagsToShow = !this.isOverlapping ? this.newTags : [];
    this.getAlltags();
    this.handleOverlapingTags();
  }

  ngAfterViewInit(): void {
    this.handleOverlapingTags();
  }

  getAlltags(): void {
    var Params = {
      entity_type: "document",
      only_tags: false,
      filter_model: '{}',
    }

    this.tagMangementService.behaviorSubjectForGeAllList.subscribe(res => {
      if (res) {
        this.alltags = res.map(t => t);
        this.filteredTags = this.tagFormControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );
        this.filteredTagList = this.alltags;
      }
    })
  }

  // @purpose: connect input and autocomplete dropdown
  // @author: Ammshathwan
  displayFn(tag: any): any {
    if (tag) {
      return tag ? tag : null;
    }
  }

  // @purpose: Filter the all available tags in BE to show in dropdown
  // @author: Ammshathwan
  _filter(content: string): any[] {
    this.isError = false;
    this.errorMessage = '';
    let filteredTags: any[] = [];
    if (content || content == '') {
      const filteredContent = content.toString().toLocaleLowerCase();
      filteredTags = this.filteredTagList.filter((tag) =>
        (tag && tag.tag.content).toString().toLowerCase().includes(filteredContent)
      )
      this.validateTagInput(content);
      this.refactorTagsLists();
    }

    if(!content){
      filteredTags = this.filteredTagList
    }

    return filteredTags;
  }

  // @purpose: remove tag from selected tag list
  // @author: Ammshathwan
  remove(tag: any): void {
    this.onInputFocusIn();
    this.tagInput.nativeElement.focus();
    if (tag) {
      const index = this.newTags.indexOf(tag);
      if (index >= 0) {
        this.newTags.splice(index, 1);
      }
      this
      this.tagsToShow = this.newTags;
      this.handleOverlapingTags();
      this.onRemoveTags(tag)
      this.onRemoveTag.emit(tag)
    }
  }

  // @purpose: add tag to selected tag list
  // @author: Ammshathwan
  selected(tag: any, subTag?: any): void {
    this.isError = false;
    this.errorMessage = ""
    this.newSubTags = [];
    this.tagInput.nativeElement.value = "";
    this.tagInput.nativeElement.focus();
    this.autoCompleteDropdown.closePanel()
    const index = this.filteredTagList.indexOf(tag);
    let status:boolean
    if (subTag && index >= 0 && tag && this.filteredTagList[index]) {
      status = false
      this.newSubTags.push({
        color_code: subTag.color_code,
        content: subTag.content,
        entity_type: subTag.entity_type,
        id: subTag.id
      })
      this.newTags.push(
        {
          sub_tags: this.newSubTags,
          tag: {
            color_code: subTag.color_code,
            content: this.filteredTagList[index].tag.content,
            entity_type: this.filteredTagList[index].tag.entity_type,
            id: this.filteredTagList[index].tag.id
          }
        }
      )
      this.removeDulicateSubTag(tag, subTag, status);
    } else {
      if (tag && !subTag && this.filteredTagList[index]) {
        const tagObj = {
            sub_tags: [],
            tag: {
              color_code: this.filteredTagList[index].tag.color_code,
              content: this.filteredTagList[index].tag.content,
              entity_type: this.filteredTagList[index].tag.entity_type,
              id: this.filteredTagList[index].tag.id
            }
        }
        const existingTag = this.newTags.filter(oldTag => oldTag.sub_tags && !oldTag.sub_tags.length && oldTag.tag.content === tagObj.tag.content)
        if(!existingTag.length){
          this.newTags.push(tagObj)
        }
      }
    }
    this.refactorTagsLists();
    this.tagsToShow = this.newTags;
    this.onSeletionTag.emit(this.newTags)
    this.handleOverlapingTags()
  }

  // @purpose: Filter and remove already selected sub tags from dropdown
  // @author: Ammshathwan
  private removeDulicateSubTag(selectedTag? , subTag?, isTagTrue?): void {
    this.filteredTagList = this.alltags;
    if(this.newTags.length){
      if(selectedTag){
        if(subTag){
          // if tag with subtag is found
          let index = this.filteredTagList.indexOf(selectedTag);
          selectedTag.sub_tags.splice(selectedTag.sub_tags.indexOf(subTag), 1);
          this.filteredTagList[index] = selectedTag;
        }else{
          // if tag with sub tag is not found
          if(selectedTag.sub_tags.length){
            let index = this.filteredTagList.indexOf(selectedTag);
            selectedTag.sub_tags.splice(selectedTag.sub_tags.indexOf(subTag), 1);
            this.filteredTagList[index] = selectedTag;
          }else{
            this.filteredTagList.splice(this.filteredTagList.indexOf(selectedTag) , 1)
          }
        }
      }
    }
    if (this.tagFormControl.value == "") {
      this.isTagFound = true;
    }
  }

  // @purpose: Filter and remove already selectedtags from dropdown
  // @author: Ammshathwan
  // @date : 18  jan 2022
  refactorTagsLists():void{
    this.filteredTagList = this.alltags
    this.newTags.forEach((tag) => {
    this.filteredTagList.filter(alltag =>{
      if(tag.tag && alltag.tag && alltag.tag.content && tag.tag.content && alltag.tag.content === tag.tag.content && alltag.sub_tags && !alltag.sub_tags.length){
        this.filteredTagList = this.filteredTagList.filter(filterdTag => filterdTag && filterdTag.tag  && filterdTag.tag.content ? filterdTag.tag.content : '' !== alltag.tag.content)
      }
      }
    )
    })
  }

  onRemoveTags(tag:any):void{
    if(tag.sub_tags && tag.sub_tags.length){
      this.filteredTagList.filter((parentTag) => {
        if(parentTag && parentTag.tag && parentTag.tag.content === tag.tag.content){
          parentTag.sub_tags.push(tag.sub_tags[tag.sub_tags.length - 1])
        }
      })
    }
  }

  // @purpose: Validate the input with tags
  // @author: Ammshathwan
  validateTagInput(content: any): void {
    this.isEditMode = true;
    let result = this.newTags.filter(alltag =>
      (content && alltag.tag.content) ?
        alltag.tag.content === content : null
    )

    let ExixtingTag = this.filteredTagList.filter(tag =>
      (content && tag.tag.content) ?
        tag.tag.content === content : null)

    if (result && result.length || ExixtingTag && ExixtingTag.length) {
      this.isError = true;
      this.errorMessage = "Tag already exists"
      this.isTagFound = true
    } else {
      this.isTagFound = (!this.inputFiled) ? true : false;
      this.isError = false;
      this.errorMessage = ""
      this.isTagFound = false
    }
  }

  // @purpose: Handle tags overlaping the width
  // @author: Ammshathwan
  handleOverlapingTags(): void {
    this.chipsTotalWidth = 0;
    this.hiddenTag = []
    this.vissibleTagList = []
    if (this.tagElem && this.isOverlapping) {
      let parentWidth = 0;
      setTimeout(() => {
        parentWidth = this.getParentOffsetWidth();
        this.tagElem.toArray().forEach((child, index) => {
        this.addChildScrollWidth(child);
          if (this.isTagWrapperIsElement(parentWidth)) {
            this.chipsTotalWidth -= child.nativeElement.scrollWidth;
            this.hiddenTag.push(child)
          }else{
            this.vissibleTagList.push(this.newTags[index])
          }
        })
      }, 1000)
    }
  }

  // @purpose: get the parent element offset width
  // @author: Ammshathwan
  // @date: 22 mar 2023
  getParentOffsetWidth():number{
    return this.tagWrapper.nativeElement && this.tagWrapper.nativeElement.offsetWidth
            ? this.tagWrapper.nativeElement.offsetWidth - 60 : 0
  }

  // @purpose: add all the child with to figure out needed lenth for all the tags
  // @author: Ammshathwan
  // @date: 22 mar 2023
  addChildScrollWidth(child:ElementRef){
    if (child && child.nativeElement.scrollWidth ){
      this.chipsTotalWidth += child.nativeElement.scrollWidth
    }
  }

  // @purpose: return true if view don't have space to show the tag
  // @author: Ammshathwan
  // @date: 22 mar 2023
  isTagWrapperIsElement(parentWidth):boolean{
    let isTrue:boolean
    if(this.tagWrapper && this.tagWrapper.nativeElement && parentWidth < this.chipsTotalWidth){
      isTrue = true;
    }
    return isTrue
  }

  // @purpose: Get the created tag and push to the array
  // @author: Savinda
  createNewtag(tag: string): void {
    const newTag: TagCreate = {
      content: tag,
      color_code: this.defaultColorCode,
      entity_type: 'Document',
      sub_tags: []
    };
    this.createTags([newTag]).then(response => {
      if (response && response.length) {
        this.tagInput.nativeElement.value = "";
        this.alltags.push({ tag: response[0], sub_tags: [] });
        this.newTags.push({ tag: response[0], sub_tags: [] });
        this.getAlltagsAfterCreate();
        this.onSeletionTag.emit(this.newTags)
      }
    });
  }

  // @purpose: Create a new ta
  // @author: Savinda
  private createTags(tags: Array<TagCreate>): Promise<Array<Tag>> {
    return this.tagMangementService.createTags(tags);
  }

  // @purpose: hide hidden tags count while focusing input
  // @author: Ammshathwan
  onInputFocusIn(): void {
    if (this.isOverlapping) {
      this.isEditMode = true;
      this.tagsToShow = this.newTags;
    }
  }

  // @purpose: show hidden tags count while focusing input
  // @author: Ammshathwan
  onInputFocusOut(): void {
    if (this.isOverlapping) {
      setTimeout(()=>{
        this.isEditMode = false;
        this.tagsToShow = this.vissibleTagList;
      }, 100)
    }
  }

  // @reason: Get the tags from BE
  // @author: Ammshathwan
  // @date: 22 nov 2021
  getAlltagsAfterCreate(): void {
    var Params = {
      entity_type: "document",
      only_tags: false,
      filter_model: '{}',
    }

    this.tagMangementService.getTagList(Params).then(res => {
      if (res && res.result) {
        this.tagMangementService.behaviorSubjectForAllTagList.next(res.result)
      }
    })
  }

  listenInputFocus():void{
    this.renderer.listen('window', 'click',(e:Event)=>{
      if(e.target !== this.inputElement.nativeElement && e.target!==this.contentElement.nativeElement){
        this.onInputFocusOut();
      }else{
        this.onInputFocusIn();
      }
    });
  }

}
