import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { AppConstants } from 'src/app/constants/app.constant';
import { Theme } from 'src/app/model/shared/theme.model';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  languageJSON = {};
  languageJSONTopPanel = {};
  isUserInDevMode = isDevMode();
  isView = false
  constructor(private http: HttpClient) { }

  getGeneralSettings(): Promise<any> {
    const apiUrl = AppConstants.Ehub_API + 'systemSettings/getSystemSettings';
    return this.http.get(apiUrl).toPromise();
  }

  getActiveDomainDetailsOfUser(userId: any) {
    let apiUrl =
      AppConstants.Ehub_API + 'menuItem/getAllLastVisitedDomainByUserId';
    return this.http.get(apiUrl, { params: { userId: userId } }).toPromise();
  }

  applyTheme(theme: Theme): void {
    if (!theme) { return; }
    if (theme.themeName === 'Light Theme') {
      document.body.classList.add('light-dark-theme');
    } else {
      document.body.classList.remove('light-dark-theme');
    }

    Object.entries(theme).forEach(([key, value]: [string, string]) => {
      if (key === 'fontFamily') {
        document.body.classList.remove(
          'font-roboto',
          'font-opensans',
          'font-lato',
          'font-montserrat',
          'font-notoSans'
        );
        document.body.classList.add(value);
      }
      document.documentElement.style.setProperty(`--${key}`, value);
    });
  }
}
