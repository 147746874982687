<div class="right-panel custom-scroll-wrapper position-relative h-100">

    <div class="list-details-wrapper">
        <span class="titel title-overflow" [matTooltip]="documentData?.title?.text" matTooltipPosition="below">{{documentData?.title?.text}}</span>
        <span class="close-button close-position" (click)="closeRightPanel()"><i class="material-icons c-pointer text-dark-black">close</i></span>

        <div class="custom-scroll-wrapper pl-1 custom-panel-height">
              <nav>
                <div class="nav nav-tabs mw-100 flex-wrap-none" id="nav-tab" role="tablist">
                  <a class="detail-tab" [class.active]="!isReferenceTabActive" [ngClass]="documentReferencePermission === 'none'? 'mw-100' : 'mw-50'" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">{{'DETAILS'| translate}}</a>
                  <a class="detail-tab mw-50" [class.active]="isReferenceTabActive" id="nav-profile-tab" [hidden]="documentReferencePermission === 'none'" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">{{'REFERENCES'| translate}}</a>
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade detail-tab-pane" id="nav-home" [ngClass]="!isReferenceTabActive ? 'show active' : ''" role="tabpanel" aria-labelledby="nav-home-tab">
                  <app-document-detail [documentData] ="documentData"></app-document-detail>
                </div>
                <div class="tab-pane fade reference-tab-pane" id="nav-profile" [ngClass]="isReferenceTabActive ? 'show active' : ''" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <app-document-reference-panel [documentData]="documentData"></app-document-reference-panel>
                </div>
              </div>
        </div>
    </div>
</div>

