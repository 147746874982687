export class AppConstants {
    public static paths = localStorage.getItem('paths') ? JSON.parse(localStorage.getItem('paths')) : null;
    public static ehubObject = localStorage.getItem('ehubObject') ? JSON.parse(localStorage.getItem('ehubObject')) : null;
    public static Ehub_API = AppConstants.paths && AppConstants.paths.EHUB_API ? AppConstants.paths.EHUB_API : 'http://localhost:8080/ehubrest/api/';
    public static Document_API = AppConstants.paths && AppConstants.paths.DOCUMENT_API ? AppConstants.paths.DOCUMENT_API : 'https://dkn8vl3sfj.execute-api.eu-west-1.amazonaws.com/dev3/v1/documents/';
    public static Ehub_UI_API = AppConstants.paths && AppConstants.paths.EHUB_FE_API ? AppConstants.paths.EHUB_FE_API : 'http://localhost:8080/ehubui/';
    public static Tag_API = AppConstants.paths && AppConstants.paths.TAG_API ? AppConstants.paths.TAG_API : 'https://v9vkemadnf.execute-api.eu-west-1.amazonaws.com/dev3/v1/tags/';
    public static Token = localStorage.getItem("ehubObject") ? JSON.parse(localStorage.getItem("ehubObject")).token : null;
    public static Cloud = AppConstants.paths && AppConstants.paths.CLOUD ? AppConstants.paths.CLOUD : 'AWS';
    public static Theme_API = AppConstants.paths && AppConstants.paths.THEME_API ? AppConstants.paths.THEME_API : 'https://qxafnr2ks8.execute-api.eu-west-1.amazonaws.com/dev/getThemePreSignedUrl';

    public static reloadLocalStorage(): void {
        this.paths = localStorage.getItem('paths') ? JSON.parse(localStorage.getItem('paths')) : null;
        this.ehubObject = localStorage.getItem('ehubObject') ? JSON.parse(localStorage.getItem('ehubObject')) : null;
        this.Ehub_API = AppConstants.paths && AppConstants.paths.EHUB_API ? AppConstants.paths.EHUB_API : 'http://localhost:8080/ehubrest/api/';
        this.Document_API = AppConstants.paths && AppConstants.paths.DOCUMENT_API ? AppConstants.paths.DOCUMENT_API : 'https://dkn8vl3sfj.execute-api.eu-west-1.amazonaws.com/dev3/v1/documents/';
        this.Ehub_UI_API = AppConstants.paths && AppConstants.paths.EHUB_FE_API ? AppConstants.paths.EHUB_FE_API : 'http://localhost:8080/ehubui/';
        this.Tag_API = AppConstants.paths && AppConstants.paths.TAG_API ? AppConstants.paths.TAG_API : 'https://v9vkemadnf.execute-api.eu-west-1.amazonaws.com/dev3/v1/tags/';
        this.Token = localStorage.getItem("ehubObject") ? JSON.parse(localStorage.getItem("ehubObject")).token : null;
        this.Cloud = AppConstants.paths && AppConstants.paths.CLOUD ? AppConstants.paths.CLOUD : 'AWS';
        this.Theme_API = AppConstants.paths && AppConstants.paths.THEME_API ? AppConstants.paths.THEME_API : 'https://qxafnr2ks8.execute-api.eu-west-1.amazonaws.com/dev/getThemePreSignedUrl';
    }
}
