import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, retry, take } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants/app.constant';
import { Theme } from 'src/app/model/shared/theme.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeBilderService {
  private appliedThemeName$ = new BehaviorSubject<string>('Dark Theme');
  private renderer: Renderer2;
  private themeKey?: string;
  public userID: string;
  private uploadURL?: string;
  
  constructor(
    private readonly http: HttpClient,
    public rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
    ) {
      this.renderer = this.rendererFactory.createRenderer(null, null);
    }

  getSystemtSetting():Promise<any>{
    const url = `${AppConstants.Ehub_API}systemSettings/getSystemSettings`;
    return this.http.get(url).toPromise()
  }

  setAppliedThemeName(theme: string) {
    this.appliedThemeName$.next(theme);
  }

  getApiGateway(): string {
    return AppConstants.Theme_API;
  }

  public setDefaultTheme(themeName: string, userId: string, themeId?: string): void {
    if (!themeName) { return; }
    if (this.appliedThemeName$.getValue() !== themeName) { this.setAppliedThemeName(themeName); }
    if (themeName === 'Light Theme') {
      document.body.classList.add('light-dark-theme');
      this.removeRemoteStyles();
      return;
    } else 
    { document.body.classList.remove('light-dark-theme'); }

    if (themeName !== 'Dark Theme') {
      this.applyRemoteStyles(userId, themeId);
    } else { this.removeRemoteStyles(); }
  }

  public removeRemoteStyles() {
    const element = document.getElementById('client-theme') as HTMLElement;
    if (element) {
      this.renderer.removeChild(this.document.body, element);
    }
  }

  public applyRemoteStyles( userID: string,themeId?: string): void {
    this.getStyle(themeId, userID).subscribe({
      next: (preSignUrl) => {
        console.log("this.renderer",this.renderer)
        const styleContainer = (document.getElementById('client-theme') as HTMLElement) || this.document.createElement('link');
        this.renderer.setProperty(styleContainer, 'id', 'client-theme');
        this.renderer.setProperty(styleContainer, 'rel', 'stylesheet');
        this.renderer.setProperty(styleContainer,'href', preSignUrl);
        this.renderer.appendChild(this.document.body, styleContainer);
      },
      error: (error: any) => console.warn('fileLoad is failed: ', error),
    });

  }

  public getStyle(themeKey: string, userId: string): Observable<string> {
    this.themeKey = themeKey;
    this.userID = userId;
    return this.presignedUrl('get').pipe(
      retry(2),
      catchError(this.handleError)
    );
  }

  private presignedUrl(method: 'get' | 'put' | 'delete'): Observable<string> {
    // remove http protocol and last "/"
    const envFolder: string = AppConstants.Ehub_UI_API.replace(/^https?:\/\//, '');
    const userId = this.userID;
    let s3ObjectMethod: string;
    switch (method) {
      case 'delete':
        s3ObjectMethod = 'deleteObject';
        break;
      case 'put':
        s3ObjectMethod = 'putObject';
        break;
      default:
        s3ObjectMethod = 'getObject';
    }
    return this.http
      .post<any>(this.getApiGateway(), {
        key: `${envFolder}${userId}/${this.themeKey}`,
        action: s3ObjectMethod,
      })
      .pipe(
        take(1),
        map((res) => {
          this.uploadURL = res;
          return res;
        }),
        retry(2),
        catchError(this.handleError)
      );
  }

  getThemeById(themeId): Promise<Theme> {
    const apiUrl = 'themeBuilder/getThemeByThemeId';
    return this.http.get<Theme>(apiUrl, { params: { themeId } }).toPromise();
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError(
      () =>
        new Error(
          'Something bad happened with fileUploader; please try again later.'
        )
    );
  }
}
