<form class="reference-form">
  <mat-form-field class="reference-form-filed" appearance="fill">
    <span class="material-icons-round icon search-i">
      search
    </span>
    <input type="text"
      placeholder="Search"
      aria-label="Search"
      matInput
      [formControl]="referenceControler"
      [matAutocomplete]="auto"
      [(ngModel)]="referenceModel"
      (ngModelChange)="referenceModelChange()"
      [readonly]="documentReferenceData?.length ? false : true">
      <span class="material-icons-round icon close-i" *ngIf="isShowReset" (click)="restInput()">
        close
      </span>
      <span class="material-icons-round icon filter-i" *ngIf="!isShowReset">
        filter_list
      </span>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <ng-container *ngIf="documentReferenceData?.length">
        <mat-option *ngFor="let reference of filteredReference | async" [value]="reference && reference.reference_name">
          <div class="single-reference" *ngIf="reference?.reference_name || reference?.reference_type">
            <div class="user-wrapper" [ngStyle]="{'background-color': getIconForReference(reference?.reference_type)?.color_code}">
              <span class="material-icons-round avatar">{{getIconForReference(reference?.reference_type)?.icon}}</span>
            </div>
            <span class="reference-name-dropdown">{{reference && reference.reference_name ? reference.reference_name : reference.reference_type ? reference?.reference_type +'#'+reference?.reference_id : ''}}</span>
          </div>
          <div class="no-reference" *ngIf="!reference?.reference_name && !reference && !isReferenceNotFound && !reference?.reference_type">{{'No reference found' | translate}}</div>
        </mat-option>
      </ng-container>
      <mat-option *ngIf="!documentReferenceData?.length || isReferenceNotFound && referenceControler.value">
        <div class="no-reference">{{'No reference found' | translate}}</div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>

<section class="accordion-section" *ngIf="documentReferenceData?.length">
  <mat-accordion multi>
    <mat-expansion-panel *ngFor="let reference of documentReferenceData" class="reference-expansion-panel">
      <mat-expansion-panel-header class="reference-expansion-header">
        <div class="reference-info" (click)="openRelatedModule($event , reference)">
          <div class="single-reference">
            <div class="user-wrapper" [ngStyle]="{'background-color': getIconForReference(reference?.reference_type)?.color_code}">
              <span class="material-icons-round avatar">{{getIconForReference(reference?.reference_type)?.icon}}</span>
            </div>
            <span class="reference-name">{{reference && reference.reference_name ? reference.reference_name : reference.reference_type ? reference?.reference_type +'#'+reference?.reference_id : ''}}</span>
          </div>
          <div class="reference-version-section">
            <span class="reference-version">V{{documentMeteData && documentMeteData.version ? documentMeteData.version : 0}}</span>
          </div>
        </div>
      </mat-expansion-panel-header>
      <span *ngFor="let tag of documentMeteData?.tags" class="reference-tag" [ngStyle]="{'background-color': tag?.colorCode ? tag?.colorCode : '#42b7ff' }">
        {{tag.name}}
      </span>
      <div *ngIf="!documentMeteData ||!documentMeteData?.tags?.length" class="no-reference">{{'No tags found' | translate}}</div>
    </mat-expansion-panel>
  </mat-accordion>
</section>

<div class="no-reference-found" *ngIf="!documentReferenceData?.length">{{'No reference found' | translate}}</div>

