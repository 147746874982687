import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DashboardService } from '../api/dashboard.service';
import { CommonService } from '../api/common.service';
import { GlobalConstants } from 'src/app/constants/global.constant';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api/user.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {

  private rightPanelDivSource = new BehaviorSubject<boolean>(false);
  rightPanelDiv = this.rightPanelDivSource.asObservable()
  private systemSettings;
  public permissions: any[] = [];
  private iconList: any[] = [];
  private  NOTIFICATION_TIMEOUT = 3000;
  public behaviorSubjectForAllPermisonIds = new BehaviorSubject<Array<any>>([]);
  behaviorSubjectForAllPermisonIds$ = this.behaviorSubjectForAllPermisonIds.asObservable();
  documentPermisionType:string = "none"

  constructor(private commonService: CommonService, private dashboardService : DashboardService, private userService: UserService,
    private toastr: ToastrService) {
    this.geticon();
    this.setPermissions();
  }

  getSystemSettings(): any {
    return this.systemSettings;
  }

  async setSystemSettings(): Promise<any> {
    try {
      const systemSettings: any = await this.commonService.getGeneralSettings();
      if (systemSettings) {
        this.systemSettings = systemSettings;
      }
    } catch (error) {}
  }

  getPermissions(): any[] {
    if (!this.permissions.length) {
      this.setPermissions();
    }
    return this.permissions;
  }

  setPermissions(): void {
    this.userService.getPermissionIds().subscribe((data) => {
      this.permissions = data;
    });
  }

  async setIconList() {
    await this.dashboardService.getIconList().subscribe((data) => {
      this.iconList = data;
    });
  }

  geticon(): any[] {
    if (!this.iconList.length) {
      this.setIconList();
    }
    return this.iconList;
  }

  addRightPanelClass(isClassAdded: boolean) {
    this.rightPanelDivSource.next(isClassAdded);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'No,';

    for (let index in headerList) {
        row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
        let line = (i+1)+'';
        for (let index in headerList) {
           let head = headerList[index];

            line += ',' + array[i][head];
        }
        str += line + '\r\n';
    }
    return str;
}

  getDomainPermissions(value, arg) {
    let result;
    if (value && value.length > 0) {
      result = value.find(ele => ele.hasOwnProperty(arg));
    }
    if (result) {
      return result[arg];
    }
    else {
      return {}
    }
  }

  getPermissionStatus(elementPermissions) {
    let selectedDomain: string = (GlobalConstants.currentDomianDetails && GlobalConstants.currentDomianDetails.domainName && GlobalConstants.currentDomianDetails.hasOwnProperty('domainName')) ? GlobalConstants.currentDomianDetails['domainName'] : '';
    let permissions = (GlobalConstants.systemSettings['permissions']) ? GlobalConstants.systemSettings['permissions'] : [];
    let permissionId;
    let elementLevel = 0;
    if (elementPermissions != {} && selectedDomain && permissions.length && elementPermissions) {
      permissionId = elementPermissions[selectedDomain];
      elementLevel = elementPermissions['level'];

      if (permissionId) {
        let validLevel = 0;
        let avoidDataList: Array<any> = [null, undefined, 'null', 'undefined', {}];

        permissions.forEach((role) => {
          if (role[permissionId] && role[permissionId]['permissionLevel'] && validLevel < role[permissionId]['permissionLevel']) {
            validLevel = role[permissionId]['permissionLevel'];
          }
        });

        if ((validLevel < elementLevel) || (validLevel == 0)) {
          return true;
        }
        else {
          return false;
        }
      }
      return true;
    }
    return true;
  }

  getPermissionStatusType(elementPermissions) {
    let selectedDomain: string = (GlobalConstants.currentDomianDetails && GlobalConstants.currentDomianDetails.domainName && GlobalConstants.currentDomianDetails.hasOwnProperty('domainName')) ? GlobalConstants.currentDomianDetails['domainName'] : '';
    let permissions = (GlobalConstants.systemSettings['permissions']) ? GlobalConstants.systemSettings['permissions'] : [];
    let permissionId;
    let elementLevel = 0;
    if (elementPermissions != {} && selectedDomain && permissions.length && elementPermissions) {
      permissionId = elementPermissions[selectedDomain];
      elementLevel = elementPermissions['level'];

      if (permissionId) {
        let validLevel = 0;
        let avoidDataList: Array<any> = [null, undefined, 'null', 'undefined', {}];

        permissions.forEach((role) => {
          if (role[permissionId] && role[permissionId]['permissionLevel'] && validLevel < role[permissionId]['permissionLevel']) {
            validLevel = role[permissionId]['permissionLevel'];
          }
        });

        switch (validLevel) {
          case 0:
            return "none";

          case 1:
            return "view";

          case 2:
            return "full";

          default:
            return "view"
        }

      }
      return "view";

    }
    return "view";
  }


  isPermissionView(key,permisslionList) {
    const permission = this.getDomainPermissions(permisslionList, key);
    const permissionLevel = this.getPermissionStatusType(permission);
    if (permissionLevel == 'view') {
      return true;
    }
    return false;
  }

  downloadFromPresigned(presigned_url,fileName){
    fetch(presigned_url).then(r => r.blob()).then(blobFile => {
      var blob = new Blob([blobFile], {
        type: blobFile.type,
      });
      const objectUrl: string = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
    },);
}

getErrorMessage(message, notificatioTime) {
  this.toastr.error(message, '', {
    timeOut: notificatioTime,
    closeButton: true,
    enableHtml: true,
    disableTimeOut: false,
    tapToDismiss: false,
    toastClass: 'notification-toastr'
  });
}
getSuccessMessage(message,notificatioTime) {
  this.toastr.success(message, '', {
    timeOut: notificatioTime,
    closeButton: true,
    enableHtml: true,
    disableTimeOut: false,
    tapToDismiss: false,
    toastClass: 'notification-toastr'
  });
}

}
