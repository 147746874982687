import { DashboardService } from 'src/app/services/api/dashboard.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatAccordion} from '@angular/material/expansion';
import { DocumentReference } from 'src/app/model/dashboard/document-reference.model';
import { AppConstants } from 'src/app/constants/app.constant';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-document-reference-panel',
  templateUrl: './document-reference-panel.component.html',
  styleUrls: ['./document-reference-panel.component.scss']
})
export class DocumentReferencePanelComponent implements OnInit {
  referenceControler = new FormControl();
  filteredReference: Observable<string[]>;
  referenceModel:string
  isShowReset:boolean
  isReferenceNotFound:boolean
  documentReferenceData:DocumentReference[];
  permissionIdsList = []
  iconList = []

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input('documentData') documentMeteData: any;

  constructor(private dashboardService: DashboardService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.getDocumentReference();
  }

  // @reason : to get the filterd result according to user input
  // @author : ammshathwan
  // @date: 1 Mar 2022
  private gerfilteredReference(value: string): string[] {
    let result = []
    if(value){
      const referenceName = value.toLowerCase();
      this.validateRecords(referenceName)
      result = this.documentReferenceData.filter(reference => reference.reference_name?reference.reference_name.toLowerCase().includes(referenceName):(reference.reference_type +'#'+reference?.reference_id).toLowerCase().includes(referenceName))
    }else{
      result = this.documentReferenceData
    }

    return result;
  }

  // @reason : find is there is any records to show
  // @author : ammshathwan
  // @date: 3 Mar 2022
  validateRecords(value):void{
    this.isReferenceNotFound = false;
    let result =  this.documentReferenceData.filter(reference =>
      (value && reference.reference_name) ?
      reference.reference_name.toLowerCase().includes(value) : reference.reference_type && reference?.reference_id? (reference.reference_type+'#'+reference?.reference_id).toLowerCase().includes(value) : null
    )
    if(result && result.length > 0){
      this.isReferenceNotFound = false;
    }else{
      this.isReferenceNotFound = true
    }
  }

  // @reason : switch between icons
  // @author : ammshathwan
  // @date: 3 Mar 2022
  referenceModelChange():void{
    if(this.referenceModel){
      this.isShowReset = true;
    }else{
      this.isShowReset = false;
    }
  }

  // @reason : clear user input
  // @author : ammshathwan
  // @date: 3 Mar 2022
  restInput():void{
    this.referenceModel = "";
  }

  // @reason : get reference list from BE
  // @author : ammshathwan
  // @date: 7 Mar 2022
  getDocumentReference():void{
    if(this.documentMeteData && this.documentMeteData.fileId){
      this.dashboardService.getDocumentRefernceList(this.documentMeteData.fileId).then(res => {
        this.documentReferenceData = res;
        this.filteredReference = this.referenceControler.valueChanges.pipe(
          startWith(''),
          map(value => this.gerfilteredReference(value)),
        );
      })
    }
  }

  // @reason : get reference icon from jason list
  // @author : ammshathwan
  // @date: 7 Mar 2022
  getIconForReference(referenceType:string):any{
    this.dashboardService.moduleIconsListObserver.subscribe(res => {
      if(res) this.iconList = res
    })
    let singleIcon = {}
    if(referenceType){
      if(referenceType.includes("case")){
        singleIcon = this.iconList.find(iconObject => iconObject.type == "case")
      }else if(referenceType.includes("alert")){
        singleIcon = this.iconList.find(iconObject => iconObject.type == "alert")
      }else if(referenceType.includes("person")){
        singleIcon = this.iconList.find(iconObject => iconObject.type == "person")
      }else if(referenceType.includes("organization")){
        singleIcon = this.iconList.find(iconObject => iconObject.type == "organization")
      }else{
        singleIcon = this.iconList.find(iconObject => iconObject.type == "case")
      }
    }
    return singleIcon
  }

  // @reason : open related module in new window
  // @author : ammshathwan
  // @date: 31 Mar 2022
  openRelatedModule(event , refrence):void{
    var URL:string = ''
    if(event){
      event.stopPropagation()
    }
    const caseTypeValue = refrence && refrence.reference_type ? refrence.reference_type.toLowerCase() : ''
    if(refrence && caseTypeValue){
      if(caseTypeValue.includes("case") &&  refrence.reference_id && this.getPermissionIds('caseManagement', 'caseDetails') !== false){
        URL = AppConstants.Ehub_UI_API+"element-new/dist/new/#/element/case-management/case/" + refrence.reference_id
      }else if(caseTypeValue.includes("alert") &&  refrence.reference_id && this.getPermissionIds('alertsData', 'alertsList') !== false){
        URL = AppConstants.Ehub_UI_API + 'element-new/dist/new/#/element/alert-management/alertsList'
      }else if(caseTypeValue.includes("entity") && refrence.meta_data && JSON.parse(refrence.meta_data) &&  refrence.reference_id && this.getPermissionIds('alertsData', 'Entity') !== false){
        URL = AppConstants.Ehub_UI_API + '/' + JSON.parse(refrence.meta_data).entity_url;
      }

      if(URL){
        window.open(URL, '_blank');
      }
    }
  }

  // @reason : get permission id
  // @author : ammshathwan
  // @date: 31 Mar 2022
  getPermissionIds(domain , featurePermission):boolean{
    var status:boolean;
    const permissions: any[] = this.sharedService.getPermissions();
    if (permissions && permissions[domain]) {
      this.sharedService.behaviorSubjectForAllPermisonIds.next(
        permissions[domain]
      );
      this.sharedService.behaviorSubjectForAllPermisonIds$.subscribe((ids) => {
        this.permissionIdsList = ids;
        var documentMetadataPermission = this.getPermissionForColumn(featurePermission);
        if(documentMetadataPermission =='none'){
          status = false
        }else{
          status = true
        }
      });
    }

    return status
  }

  // @reason : get permission level
  // @author : ammshathwan
  // @date: 31 Mar 2022
  getPermissionForColumn(key) {
    const permission = this.sharedService.getDomainPermissions(this.permissionIdsList, key);
    const permissionLevel = this.sharedService.getPermissionStatusType(permission);
    return permissionLevel;

  }
}
