<div class="mat-filed-wrapper" id="tag-container">
    <mat-form-field #contentElement class="mat-chip-list-filed tag-form-filed" [class.move-label]="hiddenTag.length > 0">
      <span class="dropdown-icon" [ngClass]="isOverlapping ? 'top-50' : ''"></span>
        <mat-chip-list #chipList>
          <div  #tagWrapper #contentElement  class="selected-tag-wrapper">
            <div class="selected-tag-chip w-85p d-flex align-items-center" [class.flex-wrap]="isEditMode" *ngIf="newTags && newTags.length">
              <mat-chip
                #tagElem
                *ngFor="let tag of tagsToShow"
                [selectable]="true"
                [removable]="true"
                (removed)="remove(tag)"
                class="common-tag tag-width"
                [style.background]="tag.tag?.color_code"
                >
                <span [matTooltip]="tag.tag.content" matTooltipPosition="above" class="mr-1 tag-text-overlap">{{tag.tag.content}} </span><span *ngIf="tag.sub_tags && tag.sub_tags.length === 1" class="tag-text-overlap"> : {{tag.sub_tags[0].content}}</span>
                <i class="material-icons close-button" matChipRemove>close</i>
              </mat-chip>
            </div>
          <input
            #tagInput
            #inputElement
            matInput
            placeholder="Tags"
            [formControl]="tagFormControl"
            [matAutocomplete]="autoComplete"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            />
            <span *ngIf="!isEditMode && hiddenTag.length > 0" class="hidden-count">+({{hiddenTag.length ? hiddenTag.length : 0}})</span>
          </div>
        </mat-chip-list>
        <mat-autocomplete #autoCompleteDropdown #autoComplete="matAutocomplete"  class="custom-tag-scroll-wrapper parent-wrapper" [disableRipple]="true"
        (optionSelected)="createNewtag($event.option.value)" matAutocomplete>
            <mat-option #contentElement class="add-new-tag border-bottom" *ngIf="!isTagFound && tagFormControl.value && !isEnableCreateNew" [value]="tagFormControl.value">
              {{'Add new tag'|translate}} " {{tagFormControl.value}} "
            </mat-option>
            <mat-option #contentElement *ngFor="let tag of filteredTags | async" [value]="tag.content" class="tag-wrapper border-bottom" [disableRipple]="true">
                <mat-accordion class="tags-accordion" [class.no-subtags]="!(tag.sub_tags && tag.sub_tags.length) > 0" multi (click)="$event.stopPropagation()">
                    <mat-expansion-panel [hideToggle]="!(tag.sub_tags && tag.sub_tags.length)" [disableRipple]="true">
                      <mat-expansion-panel-header [disableRipple]="true">
                        <div (click)="$event.stopPropagation()" class="w-100">
                            <mat-button class="parent-tag common-tag d-block chid-wrapper text-overflow" [style.background]="tag.tag?.color_code" (click)="selected(tag)" [disableRipple]="true">
                                <span class="tag-text-overlap">{{tag.tag && tag.tag.content}}</span>
                            </mat-button>
                          </div>

                      </mat-expansion-panel-header>

                        <ng-container>
                          <mat-button class="child-tag common-tag d-block chid-wrapper" *ngFor="let subTag of tag?.sub_tags" [style.background]="subTag?.color_code" (click)="selected(tag , subTag)" [disableRipple]="true">
                            <span class="tag-text-overlap">{{subTag?.content}}</span>
                          </mat-button>
                        </ng-container>
                    </mat-expansion-panel>
                  </mat-accordion>
            </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <span class="error-text" *ngIf="isError">{{errorMessage}}</span>
</div>

