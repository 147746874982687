export const GlobalConstants: any = {
  systemSettings: {},
  systemsettingsData: {},
  userRoles: [],
  userGroup: [],
  userRolesData: [],
  permissionJson: [],
  currentDomianDetails: {},
  categoryList: [],
  userDetails: {},
  users: [],
  tagList: [],
  doucmentStatusList: [],
  doucmentWorkFlowData: [],
  doucmentCategoryList: [],
}

export const DATE_FORMAT = {
  parse: {
    dateInput: 'MMM',
  },
  display: {
    dateInput: 'MMM D, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

export const NOTIFICATION_TIMEOUT_3000 = 3000;
export const NOTIFICATION_TIMEOUT_6000 = 6000;
