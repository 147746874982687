import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionDirective } from './permission.directive';
import { ModuleWithProviders } from '@angular/compiler/src/core';



@NgModule({
  declarations: [PermissionDirective],
  imports: [
    CommonModule
  ],
  exports:[PermissionDirective]
})
export class SharedDirectiveModule { 
}
