import { Component, OnInit, VERSION, Input, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Category, DropDownData } from 'src/app/model/shared/drop-down-data.model';
import { AgGridTableService } from 'src/app/services/shared/ag-grid-table.service';
import { RightPanelComponent } from '../right-panel/right-panel.component';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { map, startWith, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UploadDocumentService } from 'src/app/services/api/upload-document.service';
import { DATE_FORMAT, GlobalConstants, NOTIFICATION_TIMEOUT_3000 } from 'src/app/constants/global.constant';
import { DashboardService } from 'src/app/services/api/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/services/shared/shared.service';
import { TagManagementService } from 'src/app/services/api/tag-management.service';
@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class DocumentDetailComponent implements OnInit {
  @Input('documentData') documentMeteData;
  permissionIdsList: any[];
  documentDetailForm: FormGroup;
  filteredCategories: Observable<Category[]>;
  ListType: string;
  CategoryList = [];
  private _onDestroy = new Subject<void>();
  selectedItem: DropDownData;
  version = VERSION;
  documentData: any;
  statusList: any = [];
  selectedTags: any[] = [];
  mappedTags: any[] = [];
  documentMetadataPermission: string;
  removedTags: any[] = [];
  user: any;
  isCategoryCreateAvilable: boolean
  documentDetails: string
  isLoad: boolean;
  currentStatus: string;
  expirationDateCopy;
  isSaveDisabled: Array<boolean> = [];
  isDocExpiredHold: boolean = false;
  isStatusHold: any;

  constructor(
    public dialogRefRightPanel: MatDialogRef<RightPanelComponent>,
    private agGridTableService: AgGridTableService,
    private uploadDocumentService: UploadDocumentService,
    public dashboardService: DashboardService,
    private toastr: ToastrService,
    public sharedService: SharedService,
    private tagMangementService: TagManagementService
  ) {
    this.initializeForm();
  }

  ngOnInit(): void {
    this.dashboardService.isWorkFlowStatusLoadObserver.subscribe((resut) => {
      this.isLoad = resut
    })
    this.getPermissionIds();
    this.getNextAvailableStatus(this.documentMeteData);
    this.getCurrentStatus(this.documentMeteData);
    this.getDocumentCategory();
    this.setDocumentDate(this.documentMeteData);
    this.getMappedTags();
    this.getLoggedUser();
    this.getAlltags();
    this.getDocumentDetailPermission();
  }

  initializeForm() {
    this.documentDetailForm = new FormGroup({
      statusFormControl: new FormControl(),
      expirationDateFormControl: new FormControl(null),
      documentTitle: new FormControl(null, Validators.required),
      categoryFormControl: new FormControl(),
    });
  }

  setDocumentDate(documentMeteData): void {
    if (documentMeteData) {
      this.expirationDateCopy = documentMeteData.is_expired
        ? moment(documentMeteData.is_expired).format('YYYY-MM-DD')
        : '';
      this.documentData = {
        documentTittle:
          documentMeteData.title && documentMeteData.title.text
            ? documentMeteData.title.text
            : '',
        tags: documentMeteData.tags ? documentMeteData.tags : [],
        expirationDate: documentMeteData.is_expired
          ? moment(documentMeteData.is_expired).format('YYYY-MM-DD')
          : '',
        status: documentMeteData.status ? this.capitalize(documentMeteData.status) : 0,
        verion: documentMeteData.version ? documentMeteData.version : 1,
        filename: documentMeteData.fileName ? documentMeteData.fileName : '',
        fileType:
          documentMeteData.title && documentMeteData.title.format
            ? documentMeteData.title.format
            : '',
        uploadedDate: documentMeteData.uploadedDate
          ? moment(documentMeteData.uploadedDate).format('MMM D,YYYY, hh:mm a')
          : '',
        createdBy: documentMeteData.createdBy && documentMeteData.createdBy ? documentMeteData.createdBy : '',
        is_deleted: documentMeteData.is_deleted && documentMeteData.is_deleted ? documentMeteData.is_deleted : false
      };
      let catObj = GlobalConstants.categoryList.find(x => x.code == this.documentMeteData.category);
      this.documentDetailForm
        .get('categoryFormControl')
        .setValue(
          documentMeteData.category && catObj
            ? catObj
            : {},
        );

      this.documentDetailForm
        .get('statusFormControl')
        .setValue(documentMeteData.status ? this.capitalize(documentMeteData.status) : 0);
    }

  }

  getDocumentCategory(): void {
    this.ListType = 'Document Category';
    this.CategoryList = GlobalConstants.categoryList
    this.CategoryList.filter(x => x.code == this.documentMeteData.category)
    this.filteredCategories = this.documentDetailForm
      .get('categoryFormControl')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this.filter(value))
      );

  }

  submitDocumentData(): void {
    this.getAlltags();
    if (this.documentData.documentTittle) {
      const doc = {
        title: this.documentData ? this.documentData.documentTittle : '',
        timestamp: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
        status: this.selectedItem ? this.selectedItem.value : '',
        category: this.documentDetailForm.get('categoryFormControl').value && this.documentDetailForm.get('categoryFormControl').value.code
          ? this.documentDetailForm.get('categoryFormControl').value.code.toString()
          : '',
        docId: this.documentMeteData.fileId ? this.documentMeteData.fileId : null,
        updatedBy: (this.user && this.user.userId) ? this.user.userId.toString() : null,
        createdBy:
          (this.user && this.user.userId) ? this.user.userId.toString() : null,
        expiryDate: this.documentData && this.documentData.expirationDate ? moment(
          this.documentData.expirationDate
        ).format('YYYY-MM-DD') : ''

      };


      this.dashboardService
        .updateDocumentMetaData(doc)
        .then((resp: any) => {
          if (resp) {
            this.agGridTableService.behaviorSubjectForUploadedDocument.next(true);
            if (this.removedTags.length) {
              this.dashboardService.deleteDocumentMetaData(this.removedTags).then((resDel) => { () => resDel });
            }
            if (this.selectedTags && this.selectedTags.length) {
              this.createMap(resp)
            }
            this.closePopup();

            let message = `<div class="row">
        <div class="col-9 pad-l-0"><span class="uploaded">
        <i class="fas fa-check-circle success-icon"></i>
        <span class="pad-l-15">Successfully Updated</span></span>
        </div>
        </div>`;
            this.getSuccessMessage(message);
          }

        })
        .catch(() => {
          var message = '<div class="upload-fail">Something Went Wrong... Please try again</div>'
          this.sharedService.getErrorMessage(message, NOTIFICATION_TIMEOUT_3000)
        });
    } else {
      this.documentDetailForm.get("expirationDateFormControl").markAsDirty();
    }
  }

  capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  filter(value: string): Category[] {
    if (value) {
      const filterValue = value.toString().toLocaleLowerCase();
      return this.CategoryList.filter((option) =>
        option.displayName.toLowerCase().includes(filterValue)
      );
    } else {
      return this.CategoryList
    }
  }

  displayFn(category): string {
    return category && category.displayName ? category.displayName : '';
  }

  getBgColor(color: string) {
    if (color) {
      return this.dashboardService.hexToRGB(color, 0.2);
    }
  }

  getSelectedItem(selectedValue) {
    if (selectedValue) {
      this.currentStatus = selectedValue.value;
      this.selectedItem = selectedValue;
    }
  }

  closePopup() {
    this.dialogRefRightPanel.close();
    this.agGridTableService.setWidthFitToWindow(false);
  }

  getSuccessMessage(message) {
    this.toastr.success(message, '', {
      timeOut: 2000,
      closeButton: true,
      enableHtml: true,
      tapToDismiss: false,
      toastClass: 'notification-toastr',
    });
  }

  getPermissionIds() {
    const permissions: any[] = this.sharedService.getPermissions();
    if (permissions && permissions['documentManagement']) {
      this.sharedService.behaviorSubjectForAllPermisonIds.next(
        permissions['documentManagement']
      );
      this.sharedService.behaviorSubjectForAllPermisonIds$.subscribe((ids) => {
        this.permissionIdsList = ids;
        this.documentMetadataPermission = this.getPermissionForColumn('documentMetadata');
        if (this.documentMetadataPermission == 'none') {
          this.documentDetailForm.get('categoryFormControl').disable()
        }
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getSelectedtags(event): void {
    this.selectedTags = event
  }

  async createTagMapping(selectedTags: any[], file) {
    if (selectedTags && file && file.fileId) {
      let tagArray = [];
      selectedTags.forEach(tagElement => {
        if (!tagElement.isSelecetd) {
          var tag =
          {
            "tag_id": tagElement.tag && tagElement.tag.id,
            "subtag_id": tagElement.sub_tags && tagElement.sub_tags[0] && tagElement.sub_tags[0].id,
            "entity1_id": file.fileId,
            "entity1_type": "Document",
          }
          tagArray.push(tag);
        }
      })

      return new Promise((resolve) => {
        resolve(tagArray)
      })
    }
  }

  getPermissionForColumn(key) {
    const permission = this.sharedService.getDomainPermissions(this.permissionIdsList, key);
    const permissionLevel = this.sharedService.getPermissionStatusType(permission);
    return permissionLevel;

  }

  getMappedTags() {
    let param = { 'DocID': [this.documentMeteData.fileId ? this.documentMeteData.fileId : []] }
    this.mappedTags = [];
    this.isSaveDisabled.push(true);
    this.dashboardService.getTagMappingList(param).then((res) => {
      this.isSaveDisabled.pop();
      if (this.documentMeteData.fileId && res && res[this.documentMeteData.fileId]) {
        res[this.documentMeteData.fileId].forEach(resTag => {
          if (resTag && resTag.color_code && resTag.content && resTag.id) {
            let tagElement = resTag.content ? resTag.content : '';
            let subTagElement = resTag.subtag ? ' : ' + resTag.subtag['content'] : '';
            let tag = {
              color_code: resTag.color_code,
              content: tagElement + subTagElement,
              entity_type: "Document",
              id: resTag.id
            }
            let mappedTag = {
              tag: tag,
              subTags: resTag && resTag.subtag && resTag.subtag['id'],
              isSelecetd: true
            }
            this.mappedTags.push(mappedTag);
          }
        });
      } else {
        var message = '<div class="upload-fail">Something Went Wrong... Please try again</div>'
        this.sharedService.getErrorMessage(message, NOTIFICATION_TIMEOUT_3000)
      }
    })
  }

  OnRemove(res) {
    if (res && res.tag && this.documentMeteData.fileId && res.tag.id) {
      let tag = {
        entity1_id: this.documentMeteData.fileId,
        entity1_type: res.tag.entity_type ? res.tag.entity_type : 'Document',
        tag_id: res.tag.id,
        subtag_id: res.subTags
      }
      this.removedTags.push(tag)
    }
  }

  createMap(resp) {
    let tagArray: any = [];
    this.createTagMapping(this.selectedTags, resp).then((response) => {
      tagArray = response
      if (tagArray.length) {
        this.uploadDocumentService.createTagMapping(tagArray).then(res => {
          // notifications need to handle once mapped.
        })
      }
    });
  }

  // @reason : get the current logged user
  // @author: ammshathwan
  // @date: Dec 21 2021
  getLoggedUser(): void {
    this.isSaveDisabled.push(true);
    this.uploadDocumentService.getCurrentLoggedUserDetails()
      .then((resp) => {
        this.isSaveDisabled.pop();
        this.user = resp;
      });
  }

  // @reason : create new category
  // @author: ammshathwan
  // @date: Dec 21 2021
  async createNewCategory(category) {
    var params = {
      "allowDelete": true,
      "code": category,
      "colorCode": "",
      "disableInput": true,
      "displayName": category,
      "flagName": "",
      "flagPath": "",
      "icon": "ban",
      "listItemId": "",
      "listType": "Document Category"
    }
    await this.uploadDocumentService.addDocumentCategory(params).then(res => {
      if (res) {
        GlobalConstants.categoryList.push(res)
        this.documentDetailForm.get('categoryFormControl').setValue(res);
        this.isCategoryCreateAvilable = false;
        this.getDocumentCategory();
      }
    })
  }

  // @reason : find typed category name is exisiting
  // @author: ammshathwan
  // @date: Dec 21 2021
  inputChange(category: string): void {
    this.isCategoryCreateAvilable = false;
    let result = this.CategoryList.filter(singleCategory =>
      (category && singleCategory.displayName) ?
        singleCategory.displayName === category : null
    )
    if (result && !result.length) {
      this.isCategoryCreateAvilable = true
    }
    if (!category) {
      this.isCategoryCreateAvilable = false;
    }
  }

  // @reason: Get the tags from BE
  // @author: Ammshathwan
  // @date: 22 nov 2021
  getAlltags(): void {
    var Params = {
      entity_type: "document",
      only_tags: false,
      filter_model: '{}',
    }

    this.isSaveDisabled.push(true);
    this.tagMangementService.getTagList(Params).then(res => {
      this.isSaveDisabled.pop();
      if (res && res.result) {
        this.tagMangementService.behaviorSubjectForAllTagList.next(res.result)
      }
    })
  }

  getDocumentDetailPermission() {
    if (this.sharedService.permissions && this.sharedService.permissions["documentManagement"]) {
      this.documentDetails = this.getPermissionForColumn('documentDetails')
    }
    else {
      const permissions: any[] = this.sharedService.getPermissions();
      this.sharedService.behaviorSubjectForAllPermisonIds.next(permissions["documentManagement"]);
      this.sharedService.behaviorSubjectForAllPermisonIds$.subscribe(ids => {
        this.documentDetails = this.getPermissionForColumn('documentRepository')
      })
    }
  }

  // @reason: get dynamc icon tag
  // @author: Ammshathwan
  // @date: 13 jan 2022
  // @return : icon html string
  getStatusIcon(): string {
    return `<i class="selected-icon  mr-2 align-middle f-20 fa fa-${this.selectedItem && this.selectedItem.icon ? this.selectedItem.icon : 'ban'}"></i>`
  }

  // @reason: compare and get color code from all document status and restructure the status array
  // @author: Ammshathwan
  // @date: 23 jan 2023
  getNextAvailableStatus(documentData): void {
    this.isSaveDisabled.push(true);
    this.dashboardService.getWorkFlowStatus(documentData).then((statusList: any[]) => {
      this.isSaveDisabled.pop();
      statusList && !statusList.length ? this.statusList = [] : this.statusList = statusList;
      if (!this.statusList.length) {
        this.statusList.push(this.selectedItem);
      } else {
        this.statusList && this.statusList.length ?
          this.statusList.map((workFlowStatus, key) => {
            const isStatus = GlobalConstants.doucmentStatusList.filter((status: any) => {
              return status.value == workFlowStatus.id
            })
            this.statusList[key] = isStatus && isStatus.length ? isStatus[0] : {
              value: workFlowStatus.id,
              text: this.capitalize( workFlowStatus.name),
              icon: "ban",
              colorCode: ""
            }
          }) : []
        const statusExists: any[] = this.statusList.filter(status => status.value == this.currentStatus);
        if (statusExists && !statusExists.length) this.statusList.push(this.selectedItem);
      }
    }).catch(() => {
      this.statusList = [
        {
          value: documentData.status,
          text: this.capitalize(documentData.status),
          icon: "ban",
          colorCode: ""
        }
      ]
    });
  }

  // @reason: get current status details while load
  // @author: Ammshathwan
  // @date: 23 jan 2023
  getCurrentStatus(documentData): void {
    if (documentData && !documentData.status) return null
    this.currentStatus = (documentData.status).toLowerCase();
    this.selectedItem = GlobalConstants.doucmentStatusList.find(
      (status) => status.value == this.currentStatus
    );
    if(this.currentStatus == 'expired'){
      this.isDocExpiredHold = true;
    }
    else{
      this.isStatusHold = this.currentStatus
    }
  }

  // @reason: Auto Expire Document
  // @author: Janaka Sampath
  // @date: 18 april 2023
  onExpiryDateChange(date){
    if(moment(date).format('YYYY-MM-DD') <= moment(new Date()).format('YYYY-MM-DD') || this.currentStatus =='expired' && !date){
      this.selectedItem = GlobalConstants.doucmentStatusList.find(
        (status) => status.value == 'expired'
      );
      this.currentStatus = this.selectedItem  && this.selectedItem.value ? this.selectedItem.value : 'expired';
    }else{
     if(this.currentStatus =='expired' && this.isDocExpiredHold){
      this.selectedItem = GlobalConstants.doucmentStatusList.find(
        (status) => status.value == 'new'
      );
      this.currentStatus = this.selectedItem  && this.selectedItem.value ? this.selectedItem.value : 'new';
     }
       if(!this.isDocExpiredHold){
        this.selectedItem = GlobalConstants.doucmentStatusList.find(
          (status) => status.value == this.isStatusHold
        );
        this.currentStatus = this.selectedItem  && this.selectedItem.value ? this.selectedItem.value : this.isStatusHold;
       }
    }
 }
}
