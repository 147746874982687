import { DocumentReference } from './../../model/dashboard/document-reference.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstants } from 'src/app/constants/app.constant';
import { GlobalConstants } from 'src/app/constants/global.constant';
import { GridData } from 'src/app/model/ag-grid-table/grid-data.model';
import { GridRequestData } from 'src/app/model/ag-grid-table/grid-request-data.model';
import { DocumentRowData } from 'src/app/model/dashboard/document-row-data.model';
import { DocumentWidget } from 'src/app/model/dashboard/document-widget-data.model';
import { PieChartIcon } from 'src/app/model/shared/pie-chart-icon.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  moduleIconsListBehaviour = new BehaviorSubject([]);
  moduleIconsListObserver = this.moduleIconsListBehaviour.asObservable();

  isWorkFlowStatusLoad = new BehaviorSubject(false);
  isWorkFlowStatusLoadObserver = this.isWorkFlowStatusLoad.asObservable();

  constructor(private http: HttpClient) { }

  getDocumentList(params: GridRequestData): Promise<GridData<DocumentRowData>> {
    const apiUrl = AppConstants.Document_API + 'searchDocuments?is_repo=' + true;
    return this.http.post<GridData<DocumentRowData>>(apiUrl, params).toPromise();
  }

  getIconList(): Observable<any> {
    const apiUrl = '../../../assets/json/icon.json';
    return this.http.get(apiUrl);
  }

  updateDocumentMetaData(params): Promise<any> {
    const apiUrl = AppConstants.Document_API + 'updateDocument/' + params.docId;
    return this.http.put(apiUrl, params).toPromise();
  }

  getWidgetData(): Promise<DocumentWidget> {
    const apiUrl = AppConstants.Document_API + 'getDocumentWidgetData';
    return this.http.get<DocumentWidget>(apiUrl).toPromise();
  }

  getDownloadDocumentpresignedUrl(params: any): Promise<ArrayBuffer> {
    let apiUrl = AppConstants.Document_API + 'getDownloadLocations';
    return this.http.post<ArrayBuffer>(apiUrl, params).toPromise();
  }

  getTagMappingList(params): Promise<any> {
    let apiUrl = AppConstants.Tag_API + 'tags_by_doc_ids';
    return this.http.post<ArrayBuffer>(apiUrl, params).toPromise();
  }

  deleteDocumentMetaData(params): Promise<any> {
    const apiUrl = AppConstants.Tag_API + 'tagmappings/delete';
    return this.http.post<any>(apiUrl, params).toPromise();
  }

  deleteDocument(params): Promise<any> {
    const apiUrl = AppConstants.Document_API + 'deleteDocuments';
    return this.http.post(apiUrl, params).toPromise();
  }

  getDocumentRefernceList(documentID): Promise<DocumentReference[]> {
    const apiUrl = AppConstants.Document_API + 'getReferencesByDocumentId/' + documentID;
    return this.http.get<DocumentReference[]>(apiUrl).toPromise();
  }

  getModuleIconList(): Observable<any> {
    const apiUrl = '../../../assets/json/moduleIcons.json';
    return this.http.get(apiUrl);
  }

  getAllEntityWorkFlows(): Observable<any> {
    const apiUrl = AppConstants.Ehub_API + 'systemSettings/workflow/getAllEntityWorkflows';
    return this.http.get<any>(apiUrl);
  }

  getWorkFlowStatus(documentStatusData: any) {
    const ehubObject = JSON.parse(localStorage.getItem('ehubObject'))
    const token = ehubObject && ehubObject.token ? ehubObject.token : '';
    const entityId = "D" + (documentStatusData && documentStatusData.fileId ? documentStatusData.fileId : "");
    const currentStatus = documentStatusData && documentStatusData.status ? documentStatusData.status.toLowerCase() : "";
    const workflowKey = GlobalConstants.doucmentWorkFlowData.workflowModelKey;
    const params = `types=available`;
    const apiUrl = AppConstants.Ehub_API + `v2/workflows/process-instances/${entityId}/statuses?` + params;
    return this.http.get<any>(apiUrl).toPromise();
  }

  getAllWorkflowStatus(documentWorkFlowKey): Promise<any> {
    const apiUrl = AppConstants.Ehub_API + `v2/workflows/${documentWorkFlowKey}/statuses`;
    return this.http.get<any>(apiUrl).pipe(
      map((response :any) => {
        return response.data;
        })
    ).toPromise();
  }

  // @purpose : return given text in lowercase
  // @params: text
  // @author: ammshathwan
  // @date: 04 may 2023
  getLowerCaseText(text:string):string{
    if(!text) return "";

    return text.toLocaleLowerCase();
  }

  // @purpose : convert given hex code to rga
  // @params: hex , alpha
  // @author: ammshathwan
  // @date: 04 may 2023
  hexToRGB(hex?: string, alpha?: number) {
    if (hex) {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
      if (alpha) {
        return `rgba(${r},${g},${b},${alpha})`;
      } else {
        return `rgba(${r},${g},${b})`;
      }
    } else {
      return `rgba(${255},${255},${255},${alpha})`;
    }
  }

  // @purpose : check and return the icon with fa class
  // @params: status object
  // @author: ammshathwan
  // @date: 04 may 2023
  getIcon(item:PieChartIcon){
    return item && item.icon ? `fa-${item.icon}` : "fa-ban"
  }
  getDocumentbyId(params: GridRequestData){
    const apiUrl = AppConstants.Document_API + 'searchDocuments?is_repo=' + true;
    return this.http.post<any>(apiUrl, params);
  }
}
