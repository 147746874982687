import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { AppConstants } from './constants/app.constant';
import { GlobalConstants } from './constants/global.constant';
import { DropDownData } from './model/shared/drop-down-data.model';
import { CommonService } from './services/api/common.service';
import { UploadDocumentService } from './services/api/upload-document.service';
import { UserService } from './services/api/user.service';
import { LocalizationService } from './services/shared/localization.service';
import { SharedService } from './services/shared/shared.service';
import { ThemeBilderService } from './services/shared/theme-bilder.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  title = 'documents-microfrontend-srv';
  systemSettings: any = [];
  public keys = {
    maxFileSize: 'Maximum file size allowed',
  };
  getBasicData: boolean;
  CategoryList: any;
  systemSettingData : any = {};

  constructor(
    private sharedService: SharedService,
    private commonService: CommonService,
    private userService: UserService,
    private localizationService: LocalizationService,
    private uploadDocumentService: UploadDocumentService,
    private render2: Renderer2,
    private themeBilderService: ThemeBilderService
  ) {
    if (window.location.ancestorOrigins && window.location.ancestorOrigins.length) {
      window.parent.window.postMessage('DMS READY', document.referrer);
      this.render2.addClass(document.body , 'document-detail-panel-open')
    }
    else {
      this.loadPage();
    }
  }

  @HostListener('window:message', ['$event'])
  onMessage(e: MessageEvent): void {
    if (e.data && e.data.ehubObject && e.data.paths) {
      localStorage.setItem('ehubObject', e.data.ehubObject);
      localStorage.setItem('paths', e.data.paths);
      if (e.data.theme) {
        this.commonService.applyTheme(e.data.theme);
      }
      AppConstants.reloadLocalStorage();
      this.loadPage();
    }
  }

  ngOnInit(): void {
    this.getSystemAPIData()
  }

  loadPage(): void {
    this.setLocalization();
    Promise.all([this.getSystemSettings(), this.getUserRoles(), this.getUserGroups(), this.getUserCurrentDomainDetails(), this.permissionMappingJson(), this.getDocumentCategory(), this.getAllActiveUsers()]).then((values) => {
      GlobalConstants.systemSettings = this.systemSettings;
      this.getBasicData = true;
    });
  }

  setLocalization(): void {
    const language = (AppConstants.ehubObject && AppConstants.ehubObject['language']) ? AppConstants.ehubObject['language'] : 'English';
    this.localizationService.setLocalization('document.json', language ? language : 'English');
  }

  getSystemSettings() {
    const fileTypes: string[] = [
      'pdf',
      'xlsx',
      'txt',
      'jpg',
      'png',
      'docx',
      'csv',
      'gif',
      'rtf',
      'tiff',
      'snt',
    ];
    this.systemSettings['allowedFileTypes'] = [];
    this.systemSettings['maxFileSize'] = null;
    let systemSettings = new Promise(async (resolve, reject) => {
      const systemSettingsResult: any = this.sharedService.setSystemSettings();;
      if (systemSettingsResult) {
        this.manipulateSystemSettings(systemSettingsResult, fileTypes, resolve);
      } else {
        try {
          const response = await this.sharedService.getSystemSettings();
          if (response) {
            this.manipulateSystemSettings(response, fileTypes, resolve);
          } else {
            resolve(this.systemSettings);
          }
        } catch (error) {
          resolve(this.systemSettings);
        }
      }
    });
    return systemSettings;
  }

  manipulateSystemSettings(response, fileTypes: string[], resolveVal): void {
    GlobalConstants.systemsettingsData = response;
    if (this.isGeneralSettings(response)) { this.setGeneralSettingsTypes(response , fileTypes) }
    if (this.isUserManagementRegulation(response)) { this.setUserManagementRegulation(response) }
    if (response['Mail Settings']) { this.setMailSettings(response) }
    resolveVal(this.systemSettings);
  }

  getUserCurrentDomainDetails() {
    let currentDomian = new Promise((resolve, reject) => {
      this.commonService
        .getActiveDomainDetailsOfUser(
          this.systemSettings['ehubObject']['userId']
        )
        .then((resp) => {
          if (resp) {
            GlobalConstants.currentDomianDetails = resp['data'];
          }
          resolve(resp['data']);
        });
    });
    return currentDomian;
  }

  getUserRoles() {
    this.systemSettings['userRoles'] = [];
    var systemSettings = new Promise((resolve, reject) => {
      if (localStorage.getItem('ehubObject')) {
        this.systemSettings['ehubObject'] = JSON.parse(
          localStorage.getItem('ehubObject')
        );
        if (this.systemSettings['ehubObject']['userId']) {
          this.userService
            .getUserRoles({
              userId: this.systemSettings['ehubObject']['userId'],
              isGroupRolesRequired: true,
            })
            .then((response: any) => {
              if (Array.isArray(response)) {
                let allRoleIds = [];
                response.forEach((element: any) => {
                  if (element['roleId']) {
                    allRoleIds.push(
                      element['roleId']['roleId']
                        ? element['roleId']['roleId']
                        : 0
                    );
                    this.systemSettings['userRoles'].push(element['roleId']);
                  }
                });
                this.userService
                  .getPermissionsByRole(allRoleIds)
                  .then((resp: any) => {
                    if (resp && resp.status && resp.status == 'success') {
                      this.systemSettings['permissions'] = [];
                      allRoleIds.forEach((roleId) => {
                        this.systemSettings['permissions'][roleId] = [];
                      });
                      if (resp.data && Array.isArray(resp.data)) {
                        resp.data.forEach((value) => {
                          let roleId =
                            value && value.roleId && value.roleId.roleId
                              ? value.roleId.roleId
                              : 0;
                          if (roleId) {
                            let permissionId =
                              value && value.permissionId
                                ? value.permissionId
                                : null;
                            let permissionLevel = value
                              ? value.permissionLevel
                              : null;
                            if (permissionId && permissionId.permissionId) {
                              this.systemSettings['permissions'][roleId][
                                permissionId.permissionId
                              ] = {
                                permissionId: permissionId,
                                permissionLevel: permissionLevel,
                              };
                            }
                          }
                        });
                        GlobalConstants.rolePermissions = resp.data;
                        GlobalConstants.getPermissionsByRole = resp.data;
                      }
                      resolve(this.systemSettings);
                    } else {
                      resolve(this.systemSettings);
                    }
                  })
                  .catch((response) => {
                    resolve(this.systemSettings);
                  });
              } else {
                resolve(this.systemSettings);
              }
            })
            .catch((err) => {
              resolve(this.systemSettings);
            });
        } else {
          resolve(this.systemSettings);
        }
      } else {
        resolve(this.systemSettings);
      }
    });
    return systemSettings;
  }

  permissionMappingJson() {
    let permissionJson = new Promise((resolve, reject) => {
      this.userService.getPermissionIds().subscribe((resp) => {
        if (resp) {
          GlobalConstants.permissionJson = resp;
        }
        resolve(resp);
      });
    });
    return permissionJson;
  }

  getUserGroups() {
    this.systemSettings['userGroups'] = [];
    this.systemSettings['allGroups'] = [];
    this.systemSettings['allRoles'] = [];
    var systemSettings = new Promise((resolve, reject) => {
      if (localStorage.getItem('ehubObject')) {
        this.systemSettings['ehubObject'] = JSON.parse(
          localStorage.getItem('ehubObject')
        );
        if (this.systemSettings['ehubObject']['userId']) {
          this.userService
            .getUserGroups(this.systemSettings['ehubObject']['userId'])
            .then((response: any) => {
              this.systemSettings['userGroups'] = response.result
                ? response.result
                : [];
              resolve(this.systemSettings);
            })
            .catch((err) => {
              resolve(this.systemSettings);
            });
          this.userService
            .getAllGroups()
            .then((response: any) => {
              this.systemSettings['allGroups'] = JSON.parse(response.data);
            })
            .catch((error: any) => { console.log(error) });
          this.userService
            .getAllRoles()
            .then((response: any) => {
              this.systemSettings['allRoles'] = response.data;
            })
            .catch((error: any) => { console.log(error) });
        } else {
          resolve(this.systemSettings);
        }
      } else {
        resolve(this.systemSettings);
      }
    });
    return systemSettings;
  }

  getDocumentCategory() {
    let listItem = 'Document Category';
    var categoryList = new Promise((resolve, reject) => {
      this.uploadDocumentService
        .getListItemsByListType(listItem)
        .then((resp) => {
          if (resp) {
            GlobalConstants.categoryList = resp;
          }
          resolve(resp);
        });
      return categoryList;
    });

  }

  getAllActiveUsers(): Promise<Array<DropDownData>> {
    return this.userService.getAllUsers()
      .then(res =>
        res.result.filter(user => (user.statusId && user.statusId.code && user.statusId.code === 'Active'))

      )
      .then(res =>
        GlobalConstants.users = res.map(user => {
          return { value: user.userId.toString(), text: user.screenName }
        })
      ).catch(() => []);
  }

  checkForTheme(){
    const usrObj = JSON.parse(localStorage.getItem('ehubObject'));
    const themesSettings = this.systemSettingData['General Settings'].filter(el => el.section === 'Theme');
    const themeSetting = themesSettings.find(el => {
      return el.name === el.selectedValue;
    });

    if (themeSetting && themeSetting.name) {
      this.themeBilderService.setDefaultTheme(themeSetting.name, usrObj.userId, themeSetting.settingId);
    }
  }

  getSystemAPIData(){
    this.themeBilderService.getSystemtSetting().then(res => {
      this.systemSettingData = res;
      if(res){
        this.checkForTheme();
      }
    });
  }


  // @purpose: validate given response is related to general settings
  // @author: Ammshathwan
  // @date: 22 mar 2023
  // @return : boolean
  isGeneralSettings(response):boolean{
    return response['General Settings'] && Array.isArray(response['General Settings']) ? true : false;
  }

  // @purpose: validate given response is related to user management regulation
  // @author: Ammshathwan
  // @date: 22 mar 2023
  // @return : boolean
  isUserManagementRegulation(response):boolean{
    return response['User Management Regulation'] && Array.isArray(response['User Management Regulation']) ? true : false;
  }


  // @purpose: update local system settings variable with api response
  // @author: Ammshathwan
  // @date: 22 mar 2023
  setGeneralSettingsTypes(response , fileTypes):void{
    response['General Settings'].forEach((obj) => {
      if (
        obj &&
        obj.name &&
        obj.selectedValue &&
        fileTypes.indexOf(obj.name.toLowerCase()) !== -1 &&
        obj.selectedValue.toLowerCase() === 'on'
      ) {
        this.systemSettings['allowedFileTypes'].push(obj.name.toLowerCase());
      } else if (
        obj &&
        obj.name &&
        obj.selectedValue &&
        obj.name === this.keys['maxFileSize']
      ) {
        this.systemSettings['maxFileSize'] = obj.selectedValue;
      }
    });
  }

  // @purpose: update local user management regulation variable with api response
  // @author: Ammshathwan
  // @date: 22 mar 2023
  setUserManagementRegulation(response):void {
    response['User Management Regulation'].forEach((val) => {
      if (val && val.name) {
        if (
          val.name.split('-')[0] === 'Allow to create manually' ||
          val.name.split('-')[0] === 'Allow update manually' ||
          val.name.split('-')[0] === 'Allow 3rd Party synchronization'
        ) {
          this.systemSettings[val.name] =
            val['selectedValue'] === 'On' ? true : false;
        }
      }
    });
  }

  // @purpose: update local mail settings variable with api response
  // @author: Ammshathwan
  // @date: 22 mar 2023
  setMailSettings(response):void{
    response['Mail Settings'].forEach((valu) => {
      if (valu && valu.name && valu.name === 'Enable Authentication') {
        this.systemSettings[valu.name] =
          valu.selectedValue === 'On' ? true : false;
      }
    });
  }
}
