<div class="screening-batch-main position-relative p-0">
    <div class="screening-batch-header light-theme-border-bottom">
        <h2 *ngIf="!versionScreen" class="upload-file text-dark-black gotham-medium font-medium text-on-surface">{{'Upload document(s)' |
            translate}}</h2>
        <h2 *ngIf="versionScreen" class="upload-file text-dark-black gotham-medium font-medium text-on-surface">{{'Existing document(s)' |
            translate}}</h2>
        <i class="material-icons c-pointer color-image text-dark-black" (click)="close()">close</i>
    </div>
    <div class="screening-batch-section-one" *ngIf="!describeScreen && uploadScreen">
        <div class="mb-4" *ngIf="spinner && uploadScreen">
            <div class="row">
                <div class="col-6"><span class="mat-progress">{{uploadFileList?.length}} {{'Document(s)' | translate}}</span></div>
                <div class="col-6 text-end"><span class="mat-progress">{{matProgress}}%</span></div>
            </div>
            <mat-progress-bar mode="determinate"  value="{{matProgress}}"></mat-progress-bar>
        </div>
        <div class="section-one">
            <div *ngIf="!versionNotification">
                <app-notification></app-notification>
            </div>
            <div class="file-upload-main" [class.disable-content]="spinner && uploadScreen">
                <div class="file-upload-box">
                    <div ng2FileDrop (ngClass)="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                        [uploader]="uploader" multiple class="file-drag-drop" (onFileDrop)="onFileSelected($event)">
                        <p><i class="material-icons upload-icon light-theme-light-black">cloud_upload</i></p>
                        <p class="f-24 text-dark-black title-text-on-surface ">{{'Drag and drop file(s)' | translate}}</p>
                        <p class="f-10 color-image text-dark-black text-on-surface text-uppercase">{{'Or' | translate}}</p>
                        <label>
                            <input type="file" style="display:none" #filesupload class="file-upload-btn"
                                (onFileSelected)="onFileSelected($event)" multiple ng2FileSelect
                                [uploader]="uploader" />
                            <span #filesupload class="f-14 file-upload-btn light-theme-btn primary-btn">{{'CHOOSE
                                FILE(s)' | translate}}</span>
                        </label>
                        <div>
                            <div class="attachements-list mt-4 pt-2">
                                <div class="uploded-file align-items-center text-dark-black text-white py-2 rounded lh-normal d-inline-flex attachement-item f-14 mt-3 mr-3"
                                    *ngFor="let item of uploader.queue">
                                    <i class="material-icons mr-2 f-24 file-icon">insert_drive_file</i>
                                    <span class="dots-text align-top">{{(item && item._file && item._file.name) ? item._file.name : '' }}</span>
                                    <i class="material-icons ml-2 ft-20 align-top c-pointer close-icon"
                                        (click)="removeUploadedDoc(item)">close</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="download-sample-file font-12">
                    <div class="download-sample-file-alignment">
                        <p class="m-b0 text-dark-black text-on-surface max-size">{{'Maximum document size' | translate}}
                            {{fileSizeFromSystemSettings ? fileSizeFromSystemSettings : '0'}}MB. </p>
                        <p class="text-dark-black text-on-surface format-files">{{ 'File formats allowed' | translate}}:
                            {{(SSB_files_allowed.length > 0) ? SSB_files_allowed.join(", ") : 'N/A'}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex mt-2 jc-fe">
            <button mat-raised-button (click)="cancelUpload()"
                class="upload-cancle light-theme-btn pr-3 secondary-btn custom-upload-buttons">{{'CANCEL'|translate}}</button>
            <button id='menuIconID' [disabled]="fileList.length==0 || spinner || !fileSizeFromSystemSettings || !SSB_files_allowed" mat-raised-button (click)="validateUploadedFiles()"
                class="ml-3 light-theme-btn primary-btn btn-primary-upload" [ngClass]="{'c-ban op-05 c-ban-color': fileList.length==0 || spinner || !fileSizeFromSystemSettings || !SSB_files_allowed}"
                appNodbclick>{{'UPLOAD'| translate}}
            </button>
        </div>
    </div>
    <div class="screening-batch-section-second" *ngIf="!describeScreen && versionScreen && !uploadScreen">
        <div class="section-second varsion-section">
            <div  class="mb-4" *ngIf=" radioButton && !uploadScreen && exisitingDocumentLoader">
                <mat-progress-bar mode="indeterminate"  value="100"></mat-progress-bar>
            </div>
            <div class="file-upload-main" [class.disable-content]="radioButton && !uploadScreen && exisitingDocumentLoader">
                <div class="file-upload-box">
                    <div class="document-title">
                        {{'The document being uploaded is now available in the repository in an earlier version. Choose how you want to upload:' | translate}}
                    </div>
                    <div class="version w-100">
                        <table class="table table-dark version-table">
                            <thead>
                            <tr>
                                <th class="document-title-th">
                                    Document Title
                                </th>
                                <th>
                                    <mat-radio-group *ngIf='versionList?.length > 1' aria-label="Select an option" [(ngModel)]="newvesionAll" name='newvesionAll'>
                                        <mat-radio-button value="newvesionAll" (change)="radioChange($event)"></mat-radio-button>
                                    </mat-radio-group>
                                    <span class="m-l-10">As a new version</span></th>
                                <th>
                                    <mat-radio-group *ngIf='versionList?.length > 1' aria-label="Select an option" [(ngModel)]="OverrideAll" name='OverrideAll'>
                                        <mat-radio-button value="OverrideAll" (change)="radioChange($event)"></mat-radio-button>
                                    </mat-radio-group>
                                    <span class="m-l-10">Override current version</span></th>
                                <th>
                                    <mat-radio-group *ngIf='versionList?.length > 1' aria-label="Select an option" [(ngModel)]="newdocumentAll" name='newdocumentAll'>
                                        <mat-radio-button value="newdocumentAll" (change)="radioChange($event)"></mat-radio-button>
                                    </mat-radio-group>
                                    <span class="m-l-10">As a new document</span></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of versionList;let i=index">
                                <td>
                                    <span class="fas fa-file mar-l10 mar-l15 text-coral-blue pad-b0 font-20"></span>{{item.title}}
                                </td>
                                <td>
                                    <mat-radio-group aria-label="Select an option" [(ngModel)]="item.version_handler">
                                    <mat-radio-button value="new version" name='new version' (change)="radioChange($event)"></mat-radio-button>
                                    </mat-radio-group>
                                    <span class="clr-p6 fnt-12">
                                      <span class="fas fa-exclamation-triangle clr-p6 mr-4px ml-4px"></span>
                                      {{item?.reference_count ? item?.reference_count : 0}} {{'references will be lost' | translate}}
                                    </span>
                                </td>
                                <td>
                                    <mat-radio-group aria-label="Select an option" [(ngModel)]="item.version_handler">
                                    <mat-radio-button value="override" name='override' (change)="radioChange($event)"></mat-radio-button>
                                    </mat-radio-group>
                                    <span class="clr-p6 fnt-12">{{'all references are kept' | translate}}</span>
                                </td>
                                <td>
                                    <mat-radio-group aria-label="Select an option" [(ngModel)]="item.version_handler">
                                    <mat-radio-button value="new file" name='new file' (change)="radioChange($event)"></mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div class="col-1 remove-icon" (click)="deleteUplodedFile(item.file_name)">
                                        <span class="fa mar-l10 mar-l15 fa-trash c-pointer text-coral-red pad-b0 font-20"></span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex mt-2 jc-fe">
            <button mat-raised-button [disabled]="!radioButton" (click)="coutinueVersionUpload()" class="ml-3 light-theme-btn primary-btn"
                [ngClass]="{'c-ban op-05': !radioButton }" appNodbclick>{{'CONTINUE UPLOAD'| translate}}
            </button>
        </div>
    </div>
    <div class="screening-batch-section-second" *ngIf="describeScreen">
        <form class="container px-0" autocomplete="off" #uploadForm = "ngForm" [formGroup]="fileUploadGroup">
            <div class="section-second">
                <div class="file-upload-main">
                    <div class="file-upload-box" [class.disable-content]="submitSpinner">
                        <div class="document-title">
                            {{'Uploading' | translate}} {{uploadFileCount ? uploadFileCount : '0'}}{{' File(s)' | translate}}
                        </div>
                        <ng-container formArrayName="uploadListFormArray">
                        <div *ngFor="let item of uploadListFormTest.controls; let i = index" [formGroupName] = "i" class="w-100">
                            <div *ngIf="uploadListFormTest.controls[i].value.result || uploadListFormTest.controls[i].value.version_handler == 'new file'">
                                <div class="describe-document">
                                    <div class="row m-0">
                                        <div class="col-md-6 save-document-name mb-3">{{uploadListFormTest.controls[i].value.file_name}}</div>
                                        <div class="col-md-6 save-document-name text">
                                            <div class="row">
                                                <div class="col-9"></div>
                                                <!-- below is commented due to funtionality is ongoing -->
                                                <!-- <div class="col-2 confidencePercentage"> <span class="m-r5">{{confidencePercentage ? confidencePercentage :'0'}}%</span></div> -->
                                                <div class="col-1 remove-icon" (click)="deleteUplodedFile(uploadListFormTest.controls[i].value.file_name)"><span class="fa mar-l10 mar-l15 fa-trash c-pointer text-coral-red pad-b0"></span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- progress bar is commented due to funtionality is on progress -->
                                    <!-- <div class="pl-7">
                                        <mat-slider [max]="100" [min]="0" [value]="confidencePercentage" [thumbLabel]='true'
                                            class="mxw-100 w-100" [change]="false">
                                        </mat-slider>
                                    </div> -->

                                    <div class="row m-0 m-0 m-t5">
                                        <div class="col-md-6 save-document-name">{{'Document information' | translate}}</div>
                                        <div class="col-md-6 save-document-name pr-0 text d-flex align-items-center justify-content-end">
                                            <mat-label class="text-dark-black mr-4">{{'Analyse document ' | translate}}</mat-label>
                                            <mat-slide-toggle name="analysis" formControlName="analysis" [ViewOnly]="true">
                                            </mat-slide-toggle>
                                        </div>
                                    </div>

                                    <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-3 custom-user-input custom-user-input-title">
                                            <mat-form-field class="f-16 text-white position-relative document-title-filed" [class.mat-form-field-invalid] = "item.controls['title']?.invalid"
                                            >
                                                <input matInput required maxlength="100" autocomplete="off"
                                                    placeholder="Document Title" class="text-overflow"
                                                     formControlName="title" (ngModelChange)="handleInputChange(uploadListFormTest, uploadListFormTest.controls[i].value, $event)"/>
                                                <span class="material-icons filename-error-icon">error</span>
                                            </mat-form-field>
                                            <span class="is-invalid"
                                            *ngIf="item.controls['title']?.invalid">
                                            Document is Required</span>
                                            <span class="is-invalid"
                                            *ngIf="uploadListFormTest.controls[i].value.titleError">
                                            This title already exists in the repository, Please change the title.</span>
                                        </div>
                                    </div>

                                     <div class="col-md-4">
                                        <div class="mb-3 custom-user-input custom-user-input-category" >
                                            <div class="custom-mat-fiels" >
                                                <mat-form-field class="example-full-width" appearance="fill" [hidden]='documentMetadataPermission=="none"'>
                                                    <span class="dropdown-icon"></span>
                                                    <mat-label>Document Category</mat-label>
                                                    <input #CategorySingleSelect type="text"  formControlName="category" (ngModelChange)="getModelChange($event, i)"
                                                    matInput [matAutocomplete]="auto" [value] = "categoryvalue">
                                                    <mat-autocomplete class="custom-scroll-wrapper catergory-dropdown-height" #auto="matAutocomplete" [displayWith]="displayFn">
                                                        <mat-option *ngIf="isCategoryCreateAvilable" class="create-new-option"
                                                        [hidden]='documentMetadataPermission!="full"'>
                                                            <button type="button" (click)="createNewCategory(uploadListFormTest.controls[i].value.category, uploadListFormTest.controls[i])">
                                                                <span class="add-new-text">Add new document category "{{uploadListFormTest.controls[i].value.category}}"</span>
                                                            </button>
                                                        </mat-option>
                                                        <mat-option class="category-options" *ngFor="let category of filteredCategorys | async" [value]="category">
                                                        {{category && category.displayName}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                  </mat-form-field>
                                              </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="mb-3 custom-user-input  custom-user-input-tag position-relative" [hidden]='documentMetadataPermission=="none"'>
                                        <app-document-tags [isOverlapping]='true' [isEnableCreateNew]='documentMetadataPermission != "full"' (onSeletionTag)="getSelectedtags($event, uploadListFormTest.controls[i])"></app-document-tags>
                                        </div>
                                    </div>

                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!uploadListFormTest.controls[i].value.result && uploadListFormTest.controls[i].value.version_handler != 'new file'">
                                <div *ngIf="versionNotification" class="notification-box">
                                    <div class="row alert-style">
                                        <div class="col-1 p-r-0">
                                            <i class="fas fa-check-circle f-20 check-icon"></i>
                                        </div>
                                        <div class="col-10 p-l-0 message">
                                            <div class="alert-text notification-succes">{{uploadListFormTest.controls[i].value.file_name}}</div>
                                            <div class="message-text"> {{uploadListFormTest.controls[i].value.message}}</div>
                                        </div>
                                        <div class="col-1">
                                            <a class="close close-message" (click)="deleteUplodedFile(uploadListFormTest.controls[i].value.file_name)">&times;</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                        <div  *ngFor="let item of copyFiles;let i=index" class="copy-notification-box">
                            <div class="row alert-style">
                                <div class="col-1 p-r-0">
                                    <i class="fas fa-exclamation-circle f-20 copy-icon"></i>
                                </div>
                                <div class="col-10 p-l-0 message">
                                    <div class="alert-text notification-succes">{{item.file_name}}</div>
                                    <div class="message-text">File already exists in the repository.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex mt-2 jc-fe">
                <button mat-raised-button (click)="backtoupload()"
                class="upload-cancle back-to-upload light-theme-btn pr-3 secondary-btn custom-upload-buttons">{{'BACK TO UPLOAD'|translate}}</button>
                <button mat-raised-button (click)="cancelUpload()"
                    class="upload-cancle light-theme-btn pr-3 secondary-btn custom-upload-buttons">{{'CANCEL'|translate}}</button>
                <button mat-raised-button [disabled]="submitSpinner || uploadForm.form.invalid || titleSubmitError" [class.disabled-button]="uploadForm.form.invalid || titleSubmitError" (click)="submitUploadResult()" class="ml-3 light-theme-btn primary-btn"
                    [ngClass]="{'c-ban op-05': submitSpinner }" appNodbclick>{{'SUBMIT'| translate}}
                </button>
            </div>
        </form>
    </div>
</div>
