import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants/app.constant';
import { GridData } from 'src/app/model/ag-grid-table/grid-data.model';
import { User } from 'src/app/model/user/user.model';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    isUserInDevMode = isDevMode();
    constructor(private http: HttpClient) { }
    getUserRoles(params: any) {
        let apiUrl = AppConstants.Ehub_API + 'userRoles/getRolesOfUserWithObj';
        return this.http.get(apiUrl, { params: params }).toPromise();
    }

    getUserGroups(userId: string) {
        let apiUrl = AppConstants.Ehub_API + 'groups/listUserGroups';
        return this.http.get(apiUrl, { params: { userId } }).toPromise();
    }

    getPermissionsByRole(roles: Array<number>) {
        let apiUrl =
            AppConstants.Ehub_API + 'groupPermission/getPermissionsByRolesList';
        return this.http.post(apiUrl, roles).toPromise();
    }

    getPermissionIds(): Observable<any> {
        let apiUrl = this.isUserInDevMode
            ? '../../../assets/json/permissonsMapping.json'
            : 'assets/json/permissonsMapping.json';
        return this.http.get(apiUrl).pipe(retry(1), catchError(this.handleError));
    }

    getAllUsers(): Promise<GridData<User>> {
        let apiUrl = AppConstants.Ehub_API + 'usersNew/getUsers?isAllRequired=true';
        return this.http.post<GridData<User>>(apiUrl, {}).toPromise();
    }

    getAllGroups() {
        let apiUrl = AppConstants.Ehub_API + 'groups/getAllGroups';
        return this.http.get(apiUrl).toPromise();
    }

    getAllRoles() {
        let apiUrl = AppConstants.Ehub_API + 'roles/getAllRoles';
        return this.http.get(apiUrl).toPromise();
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` + `body was: ${error.error}`
            );
        }
        return throwError('Something bad happened; please try again later.');
    }

}