import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgGridTableService {

  behaviorSubjectForChangeView = new BehaviorSubject<any>('');
  behaviorSubjectForSetWidth = new BehaviorSubject<boolean>(false);

  behaviorSubjectForUploadedDocument = new BehaviorSubject(false);
   getObserverForUploadedDocument = this.behaviorSubjectForUploadedDocument.asObservable();

  
  changeView(gridName: string): any {
    this.behaviorSubjectForChangeView.next(gridName);
  }

  setWidthFitToWindow(isFitToWindow: boolean): any {
    this.behaviorSubjectForSetWidth.next(isFitToWindow);
  }

}
