import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppConstants } from '../../constants/app.constant';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.includes('.json')) {
      request = request.clone({ setHeaders: { 'x-auth-token': AppConstants.Token } });
    }

    return next.handle(request).pipe(
      tap(event => { () => ({}) }, (err: HttpErrorResponse) => { () => ({}) })
    );
  }
}
