<div class="document-detail pl-3">
  <form [formGroup]="documentDetailForm">
    <div class="row">
      <div class="custom-user-input">
        <mat-form-field class="f-16" permission [ViewOnly]='true'
          [domainPermission]="permissionIdsList | getPermissionId: 'documentMetadata'">
          <input matInput required [(ngModel)]="documentData.documentTittle" maxlength="100" autocomplete="off"
          [placeholder]="'Document Title'|translate" name="documentTitle" class="text-overflow" formControlName="documentTitle" />
        </mat-form-field>
      </div>
      <span class="is-invalid"
        *ngIf="documentDetailForm.get('documentTitle').invalid && (documentDetailForm.get('documentTitle').dirty || documentDetailForm.get('documentTitle').touched)">
        {{'Document is Required'| translate}}</span>
    </div>
    <div class="row m-t-10">
      <div class="custom-mat-fiels">
        <mat-form-field class="px-0 category-input-filed">
          <mat-label>{{'Document Category'|translate}}</mat-label>
          <input type="text" class="text-overflow category-input" matInput [matAutocomplete]="auto"
            formControlName="categoryFormControl" (ngModelChange)="inputChange($event)">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="custom-scroll-wrapper">
            <mat-option *ngIf="isCategoryCreateAvilable" class="create-new-option"
            [hidden]='documentMetadataPermission!="full"'>
              <button type="button" class="px-0 create-new-category" (click)="createNewCategory(documentDetailForm.get('categoryFormControl').value)">
                <span class="add-new-text">Add new document category "{{documentDetailForm.get('categoryFormControl').value}}"</span>
              </button>
            </mat-option>
            <mat-option *ngFor="let category of filteredCategories | async" [value]="category">
              {{ category && category.displayName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row m-t-10"
      *ngIf='documentMetadataPermission && (documentMetadataPermission == "full" || documentMetadataPermission == "view")'>
      <div class="custom-user-input right-panel-tag">
        <app-document-tags [isOverlapping]='false' [isEnableCreateNew]='documentMetadataPermission != "full"'
          [selectedTag]='mappedTags' (onSeletionTag)="getSelectedtags($event)" (onRemoveTag)="OnRemove($event)">
        </app-document-tags>
      </div>
    </div>
    <div class="row m-t-10">
      <div class="custom-user-input">
        <mat-form-field class="c-pointer text-white f-16" permission [ViewOnly]='true'
          [domainPermission]="permissionIdsList | getPermissionId: 'documentMetadata'">
          <mat-label class="text-dark-black">{{'Expiration Date'|translate}}</mat-label>
          <input matInput [matDatepicker]="picker" (ngModelChange)="onExpiryDateChange($event)" [(ngModel)]="documentData.expirationDate"
            name="birth" autocomplete="off" (click)="picker.open()" #ref="" (focus)="picker.open()" class=""
            formControlName="expirationDateFormControl">
          <span matSuffix class="mat-select-arrow"> </span>
          <mat-datepicker #picker panelClass="user-datepicker" class="text-dark-black"></mat-datepicker>
        </mat-form-field>
    </div>
    </div>

    <div class="row m-t-10 document-status-filed">
      <mat-form-field appearance="fill" class="w-100"
      [style.backgroundColor]="getBgColor(selectedItem?.colorCode)"
      permission [ViewOnly]='true' [domainPermission]="permissionIdsList | getPermissionId: 'documentStatus'">
        <span [style.color]="(selectedItem?.colorCode) ? dashboardService.hexToRGB(selectedItem.colorCode) : ''"
        [innerHTML]="getStatusIcon()"></span>
        <mat-select [(value)]="currentStatus">
          <ng-container *ngFor="let status of statusList">
            <mat-option [value]="status.value" class="f-16" (click)="getSelectedItem(status)"
            [disabled]="status.value  == currentStatus"
            [ngClass]="{'c-ban op-05':  status.value  == currentStatus}"
            *ngIf="status && status.text">
            <span class="d-flex align-items-center">
              <i class="selected-icon  mr-2 align-middle f-20" [style.color]="(status?.colorCode) ? dashboardService.hexToRGB(status.colorCode) : ''" [ngClass]="'fa fa-' + status?.icon"
                [style.color]="(status?.colorCode) ? status?.colorCode : ''"></i>
              <span>{{ status.text }}</span>
            </span>
          </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row p-t-24">
      <mat-label class="text-dark-black f-12 sub-title">{{'Created by'|translate}}</mat-label>
      <div class="custom-user-input f-14">
        <img src="../../../../../assets/Images/img_avatar.png" class='img-user' alt="Avatar"> {{documentData.createdBy ? documentData.createdBy : ''}}
      </div>
    </div>
    <div class="row p-t-24">
      <mat-label class="text-dark-black f-12 sub-title">{{'Current Version'|translate}}</mat-label>
      <div class="custom-user-input f-14">
        version {{documentData.verion}}</div>
    </div>
    <div class="row p-t-24">
      <mat-label class="text-dark-black f-12 sub-title">{{'File name'| translate}}</mat-label>
      <div class="custom-user-input f-14">
        {{documentData.filename}}</div>
    </div>
    <div class="row p-t-24">
      <mat-label class="text-dark-black f-12 sub-title">{{'File type'| translate}}</mat-label>
      <div class="custom-user-input f-14">
        .{{documentData.fileType}}</div>
    </div>
    <div class="row p-t-24">
      <mat-label class="text-dark-black f-12 sub-title">{{'Uploaded date'| translate}}</mat-label>
      <div class="custom-user-input f-14">
        {{documentData.uploadedDate}}</div>
    </div>
    <div class="row p-t-24 fl-r pb-4 bottom-fixed-button">
      <button type="button" (click)="closePopup()" class="f-14 footer-btn primary-cancel-btn">{{'Cancel'|
        translate}}</button>
      <button type="button" (click)="submitDocumentData()" class="f-14 footer-btn primary-save-btn" [ngClass]="{'c-ban op-05': documentMetadataPermission && documentMetadataPermission == 'none' || documentData?.is_deleted && documentData?.is_deleted === true ||  documentDetails ==  'view' }"
        [disabled]='isSaveDisabled.includes(true) || (documentMetadataPermission && documentMetadataPermission == "none" || documentData?.is_deleted && documentData?.is_deleted === true || documentDetails ==  "view")' >{{'Save'|translate}}</button>
    </div>
  </form>
</div>
