import { SharedService } from './../../../../services/shared/shared.service';
import { DashboardService } from './../../../../services/api/dashboard.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgGridTableService } from 'src/app/services/shared/ag-grid-table.service';

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss']
})
export class RightPanelComponent implements OnInit {

  documentData;
  documentReferencePermission: string;
  isReferenceTabActive: boolean;

  constructor(
    private agGridTableService: AgGridTableService,
    public dialogRefRightPanel: MatDialogRef<RightPanelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dashboardService: DashboardService,
  ) {
    this.documentData = data.documentData;
    this.documentReferencePermission = data.documentReferencePermission;
    this.isReferenceTabActive = data.isReferenceTabActive;
    }

  ngOnInit(): void {
    this.setScrolling();
    this.getModuleIocnsList()
  }

  setScrolling() {
    const scrollWrapper = document.getElementsByClassName('right-panel-scroll-wrapper')[0];
    if (scrollWrapper) {
      scrollWrapper.querySelector('mat-dialog-container').classList.add('custom-scroll-wrapper')
    }
  }

  closeRightPanel() {
    this.dialogRefRightPanel.close();
    this.agGridTableService.setWidthFitToWindow(false);
  }

  getModuleIocnsList():void{
    this.dashboardService.getModuleIconList().subscribe(res => {
      if(res) this.dashboardService.moduleIconsListBehaviour.next(res)
    })
  }

}
