import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetPermissionIdPipe } from './get-permission-id.pipe';
import { ModuleWithProviders } from '@angular/compiler/src/core';



@NgModule({
  declarations: [GetPermissionIdPipe],
  imports: [
    CommonModule,
  ],
  exports:[GetPermissionIdPipe]

})
export class SharedPipeModule {  
}
